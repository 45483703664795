import { createSlice } from "@reduxjs/toolkit";
export const EquipmentSlice = createSlice({
  name: "equipment",
  initialState: {
    value: [],
  },
  reducers: {
    populate_Equipment: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_Equipment } = EquipmentSlice.actions;
export default EquipmentSlice.reducer;
