import React, { useContext, useEffect } from "react";
import Header from "./Header";
import LogoIcon from "../Assert/ProfilePicture.png";
import ProfileIcon from "../Assert/UserID.png";
import MessageIcon from "../Assert/JobFunction.png";
import EmailIcon from "../Assert/WorkEmail.png";
import LogoutIcon from "../Assert/Logout.png";
import { populate_Staff } from "./Redux/StaffSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_role_Hierarchy } from "./Redux/RoleHierarchySlice";
import { useNavigate } from "react-router-dom";
import { ScreenCodeContext } from "../App";

function Profile() {
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  let roleHierarchyMaster = useSelector(
    (state) => state.callRoleHierarchy.value
  );

  const LogoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({
      logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI },
    });
  };
  let staff = useSelector((state) => state.callStaff.value);
  const dispatch = useDispatch();
  const FetchTheStaff = () => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000")
        .then((response) => {
          if (response.status == 200) {
            let tempStaff = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
              }
            }
            console.log("tempStaff" + tempStaff);
            dispatch(populate_Staff(tempStaff));
          }
        });
    }
  };
  const FetchTheRole = () => {
    if (roleHierarchyMaster.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "roleHierarchyMaster?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempRoleHierarchyMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempRoleHierarchyMaster.push(response.data[i]);
              }
            }
            dispatch(populate_role_Hierarchy(tempRoleHierarchyMaster));
          }
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheStaff();
        FetchTheRole();
      }
    }, 2000);
  }, []);
  const ProfileScreenCode = useContext(ScreenCodeContext);

  return (
    <div>
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />
          <div className="container-fluid bg-[#092E3D] profileColor">
            
            <div className="max-w-[1280px] m-auto  xl:w-[30%] lg:w-[58%] w-full flex flex-col space-y-5 py-5 px-5">
              <div className="flex flex-row items-center justify-center">
                <img className="w-24 h-24" src={LogoIcon} />
              </div>
              <div className="profileInput">
                <img className="ProfileIcons" src={ProfileIcon} />
                <p>
                  {staff.findIndex((e) => e.workEmail == user.email) != -1
                    ? staff.find((e) => e.workEmail == user.email).firstName
                    : null}
                  {staff.findIndex((e) => e.workEmail == user.email) != -1
                    ? staff.find((e) => e.workEmail == user.email).lastName
                    : null}
                </p>
              </div>
              <div className="profileInput">
                <img className="ProfileIcons" src={EmailIcon} />
                <p className="w-full">
                  {staff.findIndex((e) => e.workEmail == user.email) != -1
                    ? staff.find((e) => e.workEmail == user.email).workEmail
                    : null}
                </p>
              </div>
              <div className="profileInput">
                <img className="ProfileIcons" src={MessageIcon} />
                <p>
                  {roleHierarchyMaster.length>0?roleHierarchyMaster.findIndex(
                    (e) =>
                      e.roleDbId ==
                      staff.find((e1) => e1.workEmail === user.email).roleDbId
                  ) != -1
                    ? roleHierarchyMaster.find(
                        (e) =>
                          e.roleDbId ==
                          staff.find((e1) => e1.workEmail === user.email)
                            .roleDbId
                      ).jobFunction
                    : null:null
                  }
                </p>
              </div>
              <div
                className="profileInput cursor-pointer"
                onClick={() => LogoutFunction()}
              >
                <img className="ProfileIcons" src={LogoutIcon} />
                <p>Logout</p>
              </div>
            </div>
            <p className="text-white text-center">
              {"Screen ID : "+
                ProfileScreenCode.find(
                        (e) =>
                          e.screenId ===156
                      )?.screenCode
              }
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Profile;
