import React from "react";

function DeleteConfirmationModal(props) {
  return (
    <div className="w-screen h-screen absolute flex flex-col items-center justify-center inset-0">
      <div className="w-screen h-screen absolute bg-black opacity-[0.7] inset-0" />
      <div className="lg:max-w-3xl w-[90%] mx-auto lg:max-h-80 absolute max-h-72 rounded-xl h-full bg-white border-black">
        <div className="rounded-t-lg  lg:px-7 px-5 text-white h-[20%] xl:text-[1.25rem]  lg:text-[1rem] md:text-[1rem] sm:text-[0.875rem] xs:text-[0.75rem] bg-secondary-default flex flex-row items-center">
          <p>Confirmation</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 h-[60%] lg:px-7 px-5">
          <div className="text-center">Record once deleted cannot be retreived </div>
          <br />
          <div> Are you sure you want to delete ?</div>
        </div>
        <div className="flex flex-row items-center justify-between gap-3 lg:px-7 px-5 rounded-b-xl h-[20%]">
          <button
            className="cancelButton"
            onClick={() => props.CancelTheDeletion()}
          >
            Cancel
          </button>
          <button
            className="saveButton"
            onClick={() => props.OnDeleteItem(props.item_to_delete)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
