import { createSlice } from "@reduxjs/toolkit";
export const FailureParametersSlice = createSlice({
  name: "failure_parameter_master",
  initialState: {
    value: [],
  },
  reducers: {
    populate_failure_parameter: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_failure_parameter } = FailureParametersSlice.actions;
export default FailureParametersSlice.reducer;
