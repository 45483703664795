import {createSlice} from '@reduxjs/toolkit';
export const ProductionProcessSlice = createSlice({
  name: 'production_process',
  initialState: {
    value: [],
  },
  reducers: {
    populate_production_process: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_production_process} = ProductionProcessSlice.actions;
export default ProductionProcessSlice.reducer;
