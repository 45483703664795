import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Dashboards = () => {
  const { state } = useLocation();
  const { dashboard_link } = state;
  const {isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
      }
    }, 2000);
  }, []);
  return (
    <div className="flex flex-col">
      {isAuthenticated === true ? (
        <div>
          <Header />
          <div className="w-screen h-[85vh] flex flex-col self-center justify-center bg-[#F9FAFE] ">
            <iframe
              height="100%"
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
              width="100%"
              src={dashboard_link}
            ></iframe>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Dashboards;
