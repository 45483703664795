import { createSlice } from "@reduxjs/toolkit";
export const UserSlice = createSlice({
  name: "user",
  initialState: {
    value: "",
  },
  reducers: {
    populate_user: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_user } = UserSlice.actions;
export default UserSlice.reducer;
