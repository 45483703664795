import React from "react";

function AddPageButton(props) {
  return (
    <div className="flex flex-row items-center  justify-between h-[12%] lg:px-7 md:px-5 px-4 gap-3">
      <button
        className="cancelButton"
        onClick={() => props.close_modal(false)}
      >
        Cancel
      </button>
      <div className="flex flex-row items-center gap-3">
        <button
          className="savedraftButton"
          onClick={() => props.AddTheItem(true)}
        >
          Save as Draft
        </button>
        <button className="saveButton" onClick={() => props.AddTheItem(false)}>
          Save
        </button>
      </div>
    </div>
  );
}

export default AddPageButton;
