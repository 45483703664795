import { createSlice } from "@reduxjs/toolkit";
export const CustomerSlice = createSlice({
  name: "company",
  initialState: {
    value: [],
  },
  reducers: {
    populate_Company: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_Company } = CustomerSlice.actions;
export default CustomerSlice.reducer;
