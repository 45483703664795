import {createSlice} from '@reduxjs/toolkit';
export const SeverityCodesSlice = createSlice({
  name: 'severity_codes',
  initialState: {
    value: [],
  },
  reducers: {
    populate_severity_codes: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_severity_codes} = SeverityCodesSlice.actions;
export default SeverityCodesSlice.reducer;