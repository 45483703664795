import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_supplier } from "./Redux/SupplierSlice";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import EditPageButton from "../Button/EditPageButton";
import AddPageButton from "../Button/AddPageButton";
import ViewPageButton from "../Button/ViewPageButton";
import SuccessMessage from "../Constant/SuccessMessage";
import ManageSearch from "../Constant/ManageSearch";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function Supplier() {
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheScreensToolTips();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "supplierMaster?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  let supplierMaster = useSelector((state) => state.callSupplier.value);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "supplierMaster";

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [supplierMasterObjectErrors, setSupplierMasterObjectErrors] = useState({
    supplierCodeError: "",
    supplierNameError: "",
    addressError: "",
    contactPersonError: "",
    contactPhoneError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "supplierCodeError");
    OnChangeErrorValues("", "supplierNameError");
    OnChangeErrorValues("", "addressError");
    OnChangeErrorValues("", "contactPersonError");
    OnChangeErrorValues("", "contactPhoneError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "supplierCodeError":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          supplierCodeError: val,
        }));
        break;
      case "supplierNameError":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          supplierNameError: val,
        }));
        break;
      case "addressError":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          addressError: val,
        }));
        break;
      case "contactPersonError":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          contactPersonError: val,
        }));
        break;
      case "contactPhone":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          contactPhoneError: val,
        }));
        break;
      case "effDateFromError":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          effDateFromError: val,
        }));
        break;
      case "effDateToError":
        setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
          ...supplierMasterObjectErrors,
          effDateToError: val,
        }));
        break;
    }
  };

  //Searching
  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const [readable_enteries, set_readable_enteries] = useState(0);

  const onChangeSearchContent = (e) => {
    setLoading(true);

    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = supplierMaster.filter((e1) =>
      e1.supplierName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (supplierMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(supplierMaster[i]);
        }
      } else {
        for (let i = 0; i < supplierMaster.length; i++) {
          tempArray.push(supplierMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        supplierMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };
  ///////

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [supplierMasterArray, setsupplierMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  let product_array_ref = useRef([]);
  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [supplierMasterObject, setSupplierMasterObject] = useState({
    supplierCode: "",
    supplierName: "",
    address: "",
    contactPerson: "",
    contactPhone: null,
    main_assembly_line: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });
  //Declaration Of Delete States
  const [supplierMasterObjectDelete, setSupplierMasterObjectDelete] = useState({
    supplierCode: "",
    supplierName: "",
    address: "",
    contactPerson: "",
    contactPhone: null,
    main_assembly_line: "",
    createdTimestamp: new Date(),
    createdBy: "",
    updatedBy: "",
    updatedTimestamp: new Date(),
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });
  const onResetValue = () => {
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      supplierCode: "",
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      supplierName: "",
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      address: "",
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      contactPerson: "",
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      contactPhone: null,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      effDateFrom: new Date(),
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      main_assembly_line: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "supplierCode":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          supplierCode: e.target.value,
        }));
        break;
      case "supplierName":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          supplierName: e.target.value,
        }));
        break;
      case "address":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          address: e.target.value,
        }));
        break;
      case "contactPerson":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          contactPerson: e.target.value,
        }));
        break;
      case "main_assembly_line":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          main_assembly_line: e.target.value,
        }));
        break;
      case "contactPhone":
        if (e.target.value.length == 11) {
          setSupplierMasterObject((supplierMasterObject) => ({
            ...supplierMasterObject,
            contactPhone: e.target.value,
          }));
        }
        break;
      case "effDateFrom":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setSupplierMasterObject((supplierMasterObject) => ({
          ...supplierMasterObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          supplierMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            supplierMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(supplierMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            supplierMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (supplierMasterArray.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(supplierMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (searched_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (draft_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(supplierMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };

  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = supplierMaster.findIndex((e) => e.supplierDbId === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(supplierMaster[idx]);
    set_item_to_delete(supplierMaster[idx].supplierDbId);
    set_show_delete_confirmations(true);
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          if (response.status === 200) {
            ShowMEWSPopup(
              `${clientSideValidations.find((e) => e.validationId == 364)
                ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 364)
                ?.validationCode
              }`,
              "Success"
            );
            let temp = [...display_draft_array]
            temp.splice(temp.findIndex((e) => e.supplierDbId == item_to_delete), 1);
            set_display_draft_array(temp);
                     set_show_drafts(temp.length > 0 ? true : false)

            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "supplierMaster?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup(error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup(
                `${clientSideValidations.find((e) => e.validationId == 365)
                  ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 365)
                  ?.validationCode
                }`,
                "Success"
              );
              let temp = [...display_draft_array]
              temp.splice(temp.findIndex((e) => e.defectDbId == item_to_delete), multiple_codes.length);
              set_display_draft_array(temp);
                       set_show_drafts(temp.length > 0 ? true : false)

              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                "supplierMaster?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup(error.message, "error");
          });
      }
      set_multiple_codes([]);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      contactPhone: supplierMasterObject.contactPhone,
      supplierCode: supplierMasterObject.supplierCode,
      supplierName: supplierMasterObject.supplierName,
      address: supplierMasterObject.address,
      contactPerson: supplierMasterObject.contactPerson,
      effDateFrom: supplierMasterObject.effDateFrom,
      effDateTo:
        status === true
          ? new Date("1900-01-01")
          : supplierMasterObject.effDateTo,
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup(
              `${clientSideValidations.find((e) => e.validationId == 362)
                ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 362)
                ?.validationCode
              }`,
              "Success"
            );
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "supplierMaster?size=1000",
              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup(error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = () => {
    let deleteData = {
      contactPhone: supplierMasterObjectDelete.contactPhone,
      supplierCode: supplierMasterObjectDelete.supplierCode,
      supplierName: supplierMasterObjectDelete.supplierName,
      address: supplierMasterObjectDelete.address,
      contactPerson: supplierMasterObjectDelete.contactPerson,
      effDateFrom: GetServerDate(supplierMasterObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      contactPhone: supplierMasterObject.contactPhone,
      supplierCode: supplierMasterObject.supplierCode,
      supplierName: supplierMasterObject.supplierName,
      address: supplierMasterObject.address,
      contactPerson: supplierMasterObject.contactPerson,
      effDateFrom: GetServerDate(supplierMasterObject.effDateFrom),
      effDateTo: new Date("9999-12-31"),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .patch(post_url + "/" + selectedSupplierDbId, deleteData)
        .then((response) => {
          if (response.status === 200) {
            axios.post(post_url, data).then((response) => {
              if (response.status === 201) {
                FetchTheData(
                  process.env.REACT_APP_BASE_API_PYTHON +
                  "supplierMaster?size=1000",
                  "Patch"
                );
                ShowMEWSPopup(
                  `${clientSideValidations.find((e) => e.validationId == 363)
                    ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 363)
                    ?.validationCode
                  }`,
                  "Success"
                );
                set_show_drafts(false);
                set_mews_popup_color("#28A745");
                set_open_edit_modal(false);
                if (multiple_codes.length > 0) {
                  set_multiple_codes([]);
                }
              }
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(data);
          ShowMEWSPopup(error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };

  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Supplier Master") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "supplierCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              supplierMasterObject.supplierCode.length === 0
            ) {
              temp++;
              setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
                ...supplierMasterObjectErrors,
                supplierCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              new Date(
                supplierMasterArray.findIndex(
                  (e) => e.supplierCode === supplierMasterObject.supplierCode
                ) != -1
              ).getTime() === new Date("9999-12-31").getTime() || new Date(
                supplierMasterArray.findIndex(
                  (e) => e.supplierCode === supplierMasterObject.supplierCode
                ) != -1
              ).getTime() === new Date("1900-01-01").getTime()
            ) {
              temp++;
              setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
                ...supplierMasterObjectErrors,
                supplierCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "supplierName":
            if (
              clientSideValidations[i].validationType === "required" &&
              supplierMasterObject.supplierName.length === 0
            ) {
              temp++;
              setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
                ...supplierMasterObjectErrors,
                supplierNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(supplierMasterObject.effDateFrom).getTime() >=
              new Date(supplierMasterObject.effDateTo).getTime() && new Date(supplierMasterObject.effDateTo).getTime() != new Date("1900-01-01").getTime()
            ) {
              temp++;
              setSupplierMasterObjectErrors((supplierMasterObjectErrors) => ({
                ...supplierMasterObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          temp_display_draft_array.push(draft_array[i]);
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = supplierMaster.length;
      let temp = 0
      for (let i = 0; i < supplierMaster.length; i++) {

        if (
          new Date(supplierMaster[i].effDateTo).getTime() ===
          new Date("9999-12-31").getTime()
        ) {
          temp += 1
        }

      }
      //Get The Total Pages
      total_pages.current = Math.ceil(
        temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (supplierMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < supplierMaster.length; i++) {

          if (
            new Date(supplierMaster[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
            temp_display_array.push(supplierMaster[i]);
          }
        }
      } else {
        if (
          supplierMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          supplierMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < supplierMaster.length;
            i++
          ) {
            if (
              new Date(supplierMaster[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(supplierMaster[i]);
            }
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            if (
              new Date(supplierMaster[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(supplierMaster[i]);
            }
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_supplierMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${clientSideValidations.find((e1) => e1.validationId == 361)
                  ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 361)
                  ?.validationCode
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${clientSideValidations.find((e1) => e1.validationId == 389)
                  ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_supplierMaster.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setsupplierMasterArray(tempNonDraftArray);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }

          dispatch(populate_supplier(temp_supplierMaster));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          set_draft_array(temp_draft_array);
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup(error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectedSupplierDbId, setSelectedSupplierDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (supplierDbId, type) => {
    console.log(selectedSupplierDbId);
    setSelectedSupplierDbId(supplierDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(supplierDbId);
  };
  const SupplyValuesToModal = (supplierDbId) => {
    let dateFrom = supplierMaster.find(
      (e) => e.supplierDbId == supplierDbId
    ).effDateFrom;
    let dateTo = supplierMaster.find(
      (e) => e.supplierDbId == supplierDbId
    ).effDateTo;
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      supplierCode: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .supplierCode,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      supplierCode: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .supplierCode,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      supplierName: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .supplierName,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      supplierName: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .supplierName,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      address: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .address,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      address: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .address,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      contactPerson: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .contactPerson,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      contactPerson: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .contactPerson,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      contactPhone: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .contactPhone,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      contactPhone: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .contactPhone,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      main_assembly_line: supplierMaster.find(
        (e) => e.supplierDbId == supplierDbId
      ).main_assembly_line,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      main_assembly_line: supplierMaster.find(
        (e) => e.supplierDbId == supplierDbId
      ).main_assembly_line,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      effDateFrom: dateFrom,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      effDateFrom: dateFrom,
    }));
    setSupplierMasterObject((supplierMasterObject) => ({
      ...supplierMasterObject,
      effDateTo: dateTo,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      effDateTo: dateTo,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      createdBy: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .createdBy,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      updatedBy: supplierMaster.find((e) => e.supplierDbId == supplierDbId)
        .updatedBy,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      createdTimestamp: supplierMaster.find(
        (e) => e.supplierDbId == supplierDbId
      ).createdTimestamp,
    }));
    setSupplierMasterObjectDelete((supplierMasterObjectDelete) => ({
      ...supplierMasterObjectDelete,
      updatedTimestamp: supplierMaster.find(
        (e) => e.supplierDbId == supplierDbId
      ).updatedTimestamp,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    console.log("Sorting:" + paginatedArray);
    let Sortingsupplier = [...supplierMasterArray];
    if (sortStyle === "Descending") {
      let Updatedsupplier = Sortingsupplier.sort((a, b) => {
        const a_res = action === "Code" ? a.supplierCode : a.supplierName;
        const b_res = action === "Code" ? b.supplierCode : b.supplierName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(Updatedsupplier);
      setSortStyle("Ascending");
    }
    if (sortStyle === "Ascending") {
      let Updatedsupplier = Sortingsupplier.sort((a, b) => {
        const a_res = action === "Code" ? a.supplierCode : a.supplierName;
        const b_res = action === "Code" ? b.supplierCode : b.supplierName;

        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(Updatedsupplier);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (Sortingsupplier.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(Sortingsupplier[i]);
      }
    } else {
      for (let i = 0; i < Sortingsupplier.length; i++) {
        tempArray.push(Sortingsupplier[i]);
      }
    }

    current_page.current = 1;
    total_pages.current = Math.ceil(
      Sortingsupplier.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setsupplierMasterArray(Sortingsupplier);
  };
  const SupplierScreenCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);

  return (
    <div className="flex flex-col">
      {isAuthenticated == true ? (
        <>
          <Header />
          <div className="container-fluid">
            <SuccessMessage
              mews_popup_color={mews_popup_color}
              open_edit_modal={open_edit_modal}
              show_mews_popup={show_mews_popup}
              multiple_codes={multiple_codes}
              mews_message={mews_message}
            />
            {open_add_modal == false &&
              open_edit_modal == false &&
              open_view_modal == false ? (
              <>
                <ManageSearch
                  name="Supplier"
                  OnClickShowDrafts={OnClickShowDrafts}
                  show_drafts={show_drafts}
                  toolTipName={
                    screensToolTips.find((e) => e.toolTipDbId == 424)
                      ?.toolTipDesc
                  }
                  search_content={search_content}
                  onChangeSearchContent={onChangeSearchContent}
                  placeholder="Supplier"
                />
                <div className="tablerounds">
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>

                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Supplier Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Supplier Name
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Contact Person</th>
                          <th>Address</th>
                          <th>Contact Phone</th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.supplierDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                  (current_page.current - 1)}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {item.supplierCode}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {item.supplierName}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {item.contactPerson}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {item.address}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {item.contactPhone}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(
                                    item.supplierDbId
                                  )
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <IconComponent
                                  multiple_codes={multiple_codes}
                                  OpenModal={OpenModal}
                                  ShowDeleteConfirmation={
                                    ShowDeleteConfirmation
                                  }
                                  selectedFormDbId={item.supplierDbId}
                                />
                              </td>
                            </tr>
                          ))
                          : show_drafts === true
                            ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.supplierDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {item.supplierCode}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {item.supplierName}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {item.contactPerson}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {item.address}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {item.contactPhone}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.supplierDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.supplierDbId}
                                  />
                                </td>
                              </tr>
                            ))
                            : search_content.length > 0
                              ? paginatedArray.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.supplierDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {item.supplierCode}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {item.supplierName}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {item.contactPerson}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {item.address}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {item.contactPhone}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(item.effDateFrom).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(item.effDateFrom).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.supplierDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.supplierDbId
                                      )
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <IconComponent
                                      multiple_codes={multiple_codes}
                                      OpenModal={OpenModal}
                                      ShowDeleteConfirmation={
                                        ShowDeleteConfirmation
                                      }
                                      selectedFormDbId={item.supplierDbId}
                                    />
                                  </td>
                                </tr>
                              ))
                              : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <TableButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    screenCode={
                      SupplierScreenCode.find((e) => e.screenId === 80)
                        ?.screenCode
                    }
                    onClickAddItem={onClickAddItem}
                    OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                    multiple_codes_delete={multiple_codes.length > 1}
                  />
                </div>
              </>
            ) : null}
            {/* //View box */}
            {open_view_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>View Supplier Details</div>
                  <div>
                    {
                      SupplierScreenCode.find((e) => e.screenId === 82)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Supplier Code</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={supplierMasterObject.supplierCode}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Supplier Name</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={supplierMasterObject.supplierName}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Contact Phone</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={supplierMasterObject.contactPhone}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Address</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={supplierMasterObject.address}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Contact Person</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={supplierMasterObject.contactPerson}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Effective From</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            supplierMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                supplierMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              supplierMasterObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Effective To</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            supplierMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                supplierMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              supplierMasterObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>

                    {/* </div> */}
                  </div>
                </div>
                <ViewPageButton
                  close_modal={set_open_view_modal}
                  OpenModal={OpenModal}
                  selectedDbId={selectedSupplierDbId}
                />
              </div>
            ) : null}
            {/* //Edit Popup column */}
            {open_edit_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Edit Supplier Details</div>
                  <div>
                    {
                      SupplierScreenCode.find((e) => e.screenId === 83)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 124)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.supplierCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 8}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 124)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(8)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.supplierCode}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup ">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 122)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.supplierNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 9}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 122)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(9)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.supplierName}
                        onChange={(e) => onChangeValue(e, "supplierName", null)}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 120)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.contactPhoneError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 10}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 120)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(10)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.contactPhone}
                        onChange={(e) => onChangeValue(e, "contactPhone", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 118)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.addressError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 11}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 118)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(11)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.address}
                        onChange={(e) => onChangeValue(e, "address", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 116)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.contactPersonError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 12}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 116)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(12)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.contactPerson}
                        onChange={(e) =>
                          onChangeValue(e, "contactPerson", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 114)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.effDateFromError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 13}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 114)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(13)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            supplierMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                supplierMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              supplierMasterObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 112)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.effDateToError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 14}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 112)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(14)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            supplierMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                supplierMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              supplierMasterObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>

                <EditPageButton
                  close_modal={set_open_edit_modal}
                  EditTheItem={UpdateTheItem}
                />
              </div>
            ) : null}
            {/* //Delete Popup column */}
            {show_delete_confirmations ? (
              <DeleteConfirmationModal
                CancelTheDeletion={CancelTheDeletion}
                OnDeleteItem={OnDeleteItem}
                item_to_delete={item_to_delete}
              />
            ) : null}
            {/* //Add Popup column */}
            {open_add_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Add Supplier Details</div>
                  <div>
                    {
                      SupplierScreenCode.find((e) => e.screenId === 81)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 123)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.supplierCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 1}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 123)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(1)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.supplierCode}
                        onChange={(e) => onChangeValue(e, "supplierCode", null)}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 121)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.supplierNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 2}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 121)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(2)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.supplierName}
                        onChange={(e) => onChangeValue(e, "supplierName", null)}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 119)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.contactPhoneError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 3}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 119)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        maxLength={11}
                        onPointerEnter={() => setShowTooltip(3)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="number"
                        value={supplierMasterObject.contactPhone}
                        onChange={(e) => onChangeValue(e, "contactPhone", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 117)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.addressError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 4}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 117)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(4)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.address}
                        onChange={(e) => onChangeValue(e, "address", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 115)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.contactPersonError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 5}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 115)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(5)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={supplierMasterObject.contactPerson}
                        onChange={(e) =>
                          onChangeValue(e, "contactPerson", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 113)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.effDateFromError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 6}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 113)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(6)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            supplierMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                supplierMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              supplierMasterObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 111)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {supplierMasterObjectErrors.effDateToError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 7}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 111)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(7)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            supplierMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                supplierMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              supplierMasterObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <AddPageButton
                  close_modal={set_open_add_modal}
                  AddTheItem={AddTheItem}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default Supplier;
