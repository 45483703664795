import {createSlice} from '@reduxjs/toolkit';
export const FormSlice = createSlice({
  name: 'form',
  initialState: {
    value: [],
  },
  reducers: {
    populate_Form: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_Form} = FormSlice.actions;
export default FormSlice.reducer;
