import React, { useEffect } from "react";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { populate_user } from "./Redux/UserSlice";
import { useAuth0 } from "@auth0/auth0-react";
function Login() {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/Facility");
    } else {
      loginWithRedirect();
    }
  }, []);
  // useEffect(() => {
  //   loginWithRedirect()
  //     .then((response) => {
  //       console.log("response" + response);
  //     })
  //     .catch((err) => {
  //       console.log("err" + err);
  //     });
  // }, []);

  // useEffect(() => {
  //   dispatch(populate_user(false));
  //   window.addEventListener("keydown", handleKeyDown);
  // }, []);

  // const handleKeyDown = (e) => {
  //   // console.log(e);
  //   if (e.keyCode === 13) {
  //     console.log("Working");
  //     dispatch(populate_user(true));
  //     navigate("/Facility");
  //   }
  // };
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const onClickLogin = () => {
  //   dispatch(populate_user(true));
  //   navigate("/Facility");
  // };
  return (
    <div className="bg-white w-full h-screen flex flex-col justify-center items-center">
      {/* <div className='max-w-[1240px] mx-auto grid lg:grid-cols-2 md:grid-cols-1 gap-4'>
        <div className='lg:col-span-1 hidden md:flex flex-col items-center justify-center '>
            <img className='w-[60%] h-full object-contain ' src={LoginScreen} alt=''/>
        </div>
        <div className='lg:col-span-1 flex flex-col items-center justify-center'>
            <div className='container-col'>
                <img className='w-[20%] h-full object-contain' src={LoginScreen} alt=''/>
            </div>
            <div className='container-col'>
                <p className='text-base text-black cursor-default'>by Digi-Ubique Technologies Pvt. Ltd.</p>
            </div>
            <div className='container-col'>
                <input type='text' className='input' placeholder='Username'/>
            </div>
            <div className='container-col'>
                <input type='text' className='input' placeholder='Password'/>
            </div>
            <div className='container-col1'>
                <p className='w-[70%]  rounded-md outline-0 text-right text-base text-black cursor-pointer'>Forgot password?</p>
            </div>
            <div className='container-col'>
                <button className="button" onClick={() => loginWithRedirect()}>LOGIN</button>
            </div>
        </div>
    </div> */}
    </div>
  );
}

export default Login;
