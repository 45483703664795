import { createSlice } from "@reduxjs/toolkit";
export const CustomerProductSlice = createSlice({
  name: "customerProduct",
  initialState: {
    value: [],
  },
  reducers: {
    populate_Customer_Product: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_Customer_Product } = CustomerProductSlice.actions;
export default CustomerProductSlice.reducer;
