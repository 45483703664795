import {createSlice} from '@reduxjs/toolkit';
export const InspectionRosterSlice = createSlice({
  name: 'inspection_roster',
  initialState: {
    value: [],
  },
  reducers: {
    populate_inspection_roster: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_inspection_roster} = InspectionRosterSlice.actions;
export default InspectionRosterSlice.reducer;
