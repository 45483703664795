import React, { useContext } from "react";
import Header from "./Header";
import { ScreenCodeContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
function ReleaseNotes() {
  const { isAuthenticated } = useAuth0();

  let releaseNotes = [
    {
      id: 1,
      Header: "Release notes for version 3.9 :",
      date: "2024-09-21",
      _subName: [
        {
          name: "React responsive fixes for self-service tables fixed",
        },
        {
          name: "Just say Notifications in portal, remove 24-hour",
        },
        {
          name: "Reformat the email notification to be more friendly and intuitive",
        },
        {
          name: "Create notification template for end of shift/day digest to be pushed on email",
        },
        {
          name: "All sub-screens (add/edit/delete) in portal to be full-screen - pop ups to be avoided",
        },
        {
          name: "All the screen labels in self-service portals to have a help tip describing it",
        },
        {
          name: "Assign a unique screen ID to each screen. (XXXX-YYYY-ZZZZZ) (XXXX = Max 4 alpha customer id, YYYY = Self service functionality code, ZZZZZ = Screen id) ",
        },
        {
          name: "Delete button should only work when row is selected",
        },
        {
          name: "Enable tool tip - Screen message for view draft checkbox",
        },
        {
          name: "Reword messges which are displayed on top (e.g. after refresh)",
        },
        {
          name: "Replace Delete Confirmation (Instead of Save make it confirm delete). Give a warning message to the user that record once deleted cannot be retreived ",
        },
        {
          name: "While saving blank record Customer field validation is displaying Should be >= Eff. Date From",
        },
        {
          name: "Multiple record selection does not work on editing records. Only updates one record",
        },
        {
          name: "Editing sensor records is not working (Not able to save)",
        },
        {
          name: "Reloading sensor master is redirecting to facility",
        },
        {
          name: "Add a record and saving it is displaying no records found",
        },
        {
          name: "Field Validations not working such as phone no. should be 10 digits, data type should be number",
        },
        {
          name: "While adding content to parts screen, error message is getting displayed on the back screen. It is not visible. Change this to highlighting boxes in red which have errors rather than displaying messages. ",
        },
        {
          name: "Add BOM - Supplier field is disabled. Will it be feteched automatically ",
        },
        {
          name: "No timeout on upload PDF in upload certificate screen",
        },
        {
          name: "Can shift be a drop down - update facilitty masters which will flow into roster",
        },
        {
          name: "Don't allow back dated saving of inspections ",
        },
        {
          name: "Inspection And Review Status should be non editable",
        },
      ],
    },
    {
      id: 2,
      Header: "Release notes for version 3.8 :",
      date: "2024-06-10",
      _subName: [
        {
          name: "Automotive real-time version dashboard released",
        },
        {
          name: "All events and event notification infrastructure/front-end released",
        },
        {
          name: "Ability to define multi-level questions in inspection parameters master for P&C",
        },
      ],
    },
    {
      id: 3,
      Header: "Release notes for version 2.3 :",
      date: "2024-04-18",
      _subName: [
        { name: "Sorted inspection parameters display by row and then column" },
        { name: "Sorted inspection shift data by roster date descending" },
        { name: "Fixed the numbering and arrows for page numbers" },
        {
          name: "All the filters fixed to show only data that is available in the table and not all parent data",
        },
        { name: "Message Popup has been fixed to show for all selections" },
        { name: "BOM Filter based on Supplier is fixed" },
        { name: "Certificate filter was not working and fixed" },
        {
          name: "Existing uploaded certificate view was not working and fixed",
        },
        { name: "Rearranged of columns display across master tables" },
      ],
    },
  ];
  const ReleaseNotesScreenCode = useContext(ScreenCodeContext);
  return (
    <>
      {isAuthenticated === true ? (
        <div className="flex flex-col">
          <Header />
          <div className="container-fluid">
            <div className="flex flex-col gap-3 overflow-auto">
              {releaseNotes.map((item, index) => (
                <div className="py-2 px-3 rounded flex flex-col gap-3 bg-[#092E3D] text-white">
                  <div key={index} className="flex flex-col gap-3 p-3">
                    <div className=" flex flex-row lg:flex-nowrap flex-wrap gap-3 items-center justify-between">
                      <div className="xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1.20rem]  text-[1rem] font-medium text-white">
                        {item.Header}
                      </div>
                      <div className="md:text-[1rem] sm:text-[0.875rem] xs:text-[0.75rem] font-medium text-white">
                        {item.date}
                      </div>
                    </div>
                    {item._subName.map((sub, index1) => (
                      <li
                        key={index1}
                        className="md:text-[1rem] sm:text-[0.875rem] xs:text-[0.75rem] font-medium text-white"
                      >
                        {sub.name}
                      </li>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <p className="text-center mt-1">
              {"Screen ID : " +
                ReleaseNotesScreenCode.find((e) => e.screenId === 155)
                  ?.screenCode}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ReleaseNotes;
