import React, { useState, useEffect, useRef, useContext } from "react";
import DeleteIcon from "../Assert/Delete.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Form } from "./Redux/FormSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_form_Section_Attribute } from "./Redux/FormSectionAttributeSlice";
import RadioOnIcon from "../Assert/RadioButtonOn.png";
import RadioOffIcon from "../Assert/RadioButtonOff.png";
import PlusIcon from "../Assert/plus.png";
import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import ViewPageButton from "../Button/ViewPageButton";
import EditPageButton from "../Button/EditPageButton";
import AddPageButton from "../Button/AddPageButton";
import SuccessMessage from "../Constant/SuccessMessage";
import ManageSearch from "../Constant/ManageSearch";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function Forms() {
  const [loading, setLoading] = useState(true);

  const isFormFilled = (index) => {
    let tempCount = 0;
    if (formSectionAttributeMasterArray[index].attributeName.length == 0) {
      tempCount += 1;
    }
    if (formSectionAttributeMasterArray[index].attributeValue.length == 0) {
      tempCount += 1;
    }
    if (formSectionAttributeMasterArray[index].attributeValueType.length == 0) {
      tempCount += 1;
    }
    if (formSectionAttributeMasterArray[index].attributeDisplayRow == 0) {
      tempCount += 1;
    }
    if (formSectionAttributeMasterArray[index].attributeDisplayCol == 0) {
      tempCount += 1;
    }
    if (formSectionAttributeMasterArray[index].displayControl.length == 0) {
      tempCount += 1;
    }
    if (formSectionAttributeMasterArray[index].controlLength == 0) {
      tempCount += 1;
    }
    return tempCount;
  };
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const bottomRef = useRef(null);
  const [open_Sub_Form, setOpen_Sub_Form] = useState(false);
  const OnClickFormSection = () => {
    setOpen_Sub_Form(true);
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheScreensToolTips();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000",
          "Get",
          "FormMaster"
        );
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON +
          "formSectionAttributeMaster?size=1000",
          "Get",
          "FormSectionAttributeMaster"
        );
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  let formSectionAttribute = useSelector(
    (state) => state.callFormSectionAttribute.value
  );

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "formMaster";
  //Get The Client Side Validations
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidation = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidation.push(response.data[i]);
            }
            dispatch(populate_client_side_validation(tempClientSideValidation));
          }
        });
    }
  };
  let formMaster = useSelector((state) => state.callForm.value);
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [formsObjectErrors, setFormsObjectErrors] = useState({
    formCodeError: "",
    formNameError: "",
    versionError: "",
    versionDateError: "",
    descriptionError: "",
    instructionsError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "formCodeError");
    OnChangeErrorValues("", "formNameError");
    OnChangeErrorValues("", "versionError");
    OnChangeErrorValues("", "versionDateError");
    OnChangeErrorValues("", "descriptionError");
    OnChangeErrorValues("", "instructionsError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "formCodeError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          formCodeError: val,
        }));
        break;
      case "formNameError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          formNameError: val,
        }));
        break;
      case "processTypeError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          processTypeError: val,
        }));
        break;
      case "versionError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          versionError: val,
        }));
        break;
      case "versionDateError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          versionDateError: val,
        }));
        break;
      case "descriptionError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          descriptionError: val,
        }));
        break;
      case "instructionsError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          instructionsError: val,
        }));
        break;
      case "effDateFromError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          effDateFromError: val,
        }));
        break;
      case "effDateToError":
        setFormsObjectErrors((formsObjectErrors) => ({
          ...formsObjectErrors,
          effDateToError: val,
        }));
        break;
      default:
    }
  };
  //Searching
  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < formMaster.length; i++) {
  //     if (formMaster[i].formName !== null) {
  //       if (
  //         formMaster[i].formName
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(formMaster[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    current_page.current = 0;
    let content = e.target.value;
    set_search_content(content);
    let temp = formMaster.filter((e1) =>
      e1.formName.toLowerCase().match(content.toLowerCase().trim())
    );

    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (formMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          if (formMaster[i].formDbId != 1 && formMaster[i].formDbId != 2) {
            tempArray.push(formMaster[i]);
          }
        }
      } else {
        for (let i = 0; i < formMaster.length; i++) {
          if (formMaster[i].formDbId != 1 && formMaster[i].formDbId != 2) {
            tempArray.push(formMaster[i]);
          }
        }
      }
      current_page.current = Math.ceil(
        formMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      current_page.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    set_searched_array(temp);
    current_page.current = 1;
    setPaginatedArray(tempArray);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [formMasterArray, setFormMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [formsObject, setFormsObject] = useState({
    formCode: "",
    formName: "",
    version: "",
    versionDate: "",
    description: "",
    instructions: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });
  //Declaration Of Delete States
  const [formsObjectDelete, setFormsObjectDelete] = useState({
    formCode: "",
    formName: "",
    version: "",
    versionDate: "",
    description: "",
    instructions: "",
    createdBy: "",
    updatedBy: "",
    effDateFrom: new Date(),
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    effDateTo: new Date("9999-12-31"),
  });
  const onResetValue = () => {
    setFormsObject((formsObject) => ({
      ...formsObject,
      formCode: "",
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      formName: "",
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      version: "",
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      versionDate: "",
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      description: "",
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      instructions: "",
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      effDateFrom: new Date(),
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    switch (type) {
      case "formCode":
        setFormsObject((formsObject) => ({
          ...formsObject,
          formCode: e.target.value,
        }));
        break;
      case "formName":
        setFormsObject((formsObject) => ({
          ...formsObject,
          formName: e.target.value,
        }));
        break;
      case "version":
        setFormsObject((formsObject) => ({
          ...formsObject,
          version: e.target.value,
        }));
        break;
      case "versionDate":
        setFormsObject((formsObject) => ({
          ...formsObject,
          versionDate: e.target.value,
        }));
        break;
      case "description":
        setFormsObject((formsObject) => ({
          ...formsObject,
          description: e.target.value,
        }));
        break;
      case "instructions":
        setFormsObject((formsObject) => ({
          ...formsObject,
          instructions: e.target.value,
        }));
        break;
      case "effDateFrom":
        setFormsObject((formsObject) => ({
          ...formsObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setFormsObject((formsObject) => ({
          ...formsObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          formMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            formMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(formMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            formMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (formMasterArray.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(formMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (searched_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (draft_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(formMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = (idx) => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
    ResetTheValue("Add", idx);
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = formMaster.findIndex((e) => e.formDbId === _code);
    select_deselect_the_record(formMaster[idx].formDbId);
    set_item_to_delete(_code);
    set_show_delete_confirmations(true);
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup(
              `${clientSideValidations.find((e) => e.validationId == 359)
                ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 359)
                ?.validationCode
              }`,
              "Success"
            );
            let temp = [...display_draft_array]
            temp.splice(temp.findIndex((e) => e.formDbId == item_to_delete), 1);
            set_display_draft_array(temp);
                     set_show_drafts(temp.length > 0 ? true : false)

            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000",
              "Delete",
              "FormMaster"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "formSectionAttributeMaster?size=1000",
              "Delete",
              "FormSectionAttributeMaster"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup(error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup(
                `${clientSideValidations.find((e) => e.validationId == 360)
                  ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 360)
                  ?.validationCode
                }`,
                "Success"
              );
              let temp = [...display_draft_array]
              temp.splice(temp.findIndex((e) => e.defectDbId == item_to_delete), multiple_codes.length);
              set_display_draft_array(temp);
                       set_show_drafts(temp.length > 0 ? true : false)

              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000",
                "Delete",
                "FormMaster"
              );
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                "formSectionAttributeMaster?size=1000",
                "Delete",
                "FormSectionAttributeMaster"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup(error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const AddTheItem = (status) => {
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          formCode: formsObject.formCode,
          version: Number(formsObject.version),
          // versionDate: formsObject.versionDate,
          formName: formsObject.formName,
          description: formsObject.description,
          instructions: formsObject.instructions,
          effDateFrom: formsObject.effDateFrom,
          effDateTo:
            status === true ? new Date("1900-01-01") : formsObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : "",
          updatedBy: isAuthenticated == true ? user.name : "",
          updatedTimestamp: new Date().toISOString(),
          createdTimestamp: new Date().toISOString(),
        })
        .then((response) => {
          if (response.status === 201) {
            ShowMEWSPopup(
              `${clientSideValidations.find((e) => e.validationId == 357)
                ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 357)
                ?.validationCode
              }`,
              "Success"
            );
            set_mews_popup_color("#28A745");
            if (open_Sub_Form === true) {
              console.log("Adding SectionAttribute" + response.data.formDbId);
              AddTheFormsSectionItem(response.data.formDbId, false);
            }
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup(error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditForms = () => {
    console.log(displayNewAddFormSectionAttribute);
    let deleteData = {
      formCode: formsObjectDelete.formCode,
      version: Number(formsObjectDelete.version),
      // versionDate: formsObjectDelete.versionDate,
      description: formsObjectDelete.description,
      instructions: formsObjectDelete.instructions,
      effDateFrom: GetServerDate(formsObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      formCode: formsObject.formCode,
      formName: formsObject.formName,
      version: Number(formsObject.version),
      // versionDate: formsObject.versionDate,
      description: formsObject.description,
      instructions: formsObject.instructions,
      effDateFrom: GetServerDate(formsObject.effDateFrom),
      effDateTo: new Date("9999-12-31"),
      createdBy: isAuthenticated == true ? user.name : "",
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
      createdTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .patch(post_url + "/" + selectedformDbId, deleteData)
        .then((response) => {
          if (response.status == 200) {
            axios
              .post(post_url, data)
              .then((response) => {
                if (response.status === 201) {
                  UpdateTheFormsSectionItem(response.data.formDbId, false);
                  ShowMEWSPopup(
                    `${clientSideValidations.find((e) => e.validationId == 358)
                      ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 358)
                      ?.validationCode
                    }`,
                    "Success"
                  );
                  set_show_drafts(false);

                  set_mews_popup_color("#28A745");
                  set_open_edit_modal(false);
                  if (multiple_codes.length > 0) {
                    set_multiple_codes([]);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(data);
          ShowMEWSPopup(error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Form Master") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "formCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              formsObject.formCode.length === 0
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                formCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              new Date(
                formMaster.findIndex(
                  (e) => e.formCode === formsObject.formCode
                ) != -1
              ).getTime() === new Date("9999-12-31").getTime() || new Date(
                formMaster.findIndex(
                  (e) => e.formCode === formsObject.formCode
                ) != -1
              ).getTime() === new Date("1900-01-01").getTime()
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                formCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "formName":
            if (
              clientSideValidations[i].validationType === "required" &&
              formsObject.formName.length == 0
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                formNameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "min_length" &&
              formsObject.formName.length > 0 &&
              formsObject.formName.length < 3
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                formNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "description":
            if (
              clientSideValidations[i].validationType === "required" &&
              formsObject.description?.length === 0
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                descriptionError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "version":
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(Number(formsObject.version))
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                versionError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          // case "effDateTo":
          //   if (
          //     clientSideValidations[i].validationType ===
          //       "greater_than_equalto_date" &&
          //     formsObject.effDateTo < formsObject.effDateFrom
          //   ) {
          //     temp++;
          //     setFormsObjectErrors((formsObjectErrors) => ({
          //       ...formsObjectErrors,
          //       effDateToError: clientSideValidations[i].shortMessage,
          //     }));
          //   }
          //   break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(formsObject.effDateFrom).getTime() >=
              new Date(formsObject.effDateTo).getTime() && new Date(formsObject.effDateTo).getTime() != new Date("1900-01-01").getTime()
            ) {
              temp++;
              setFormsObjectErrors((formsObjectErrors) => ({
                ...formsObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };

  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          if (
            new Date(draft_array[i].effDateTo).getTime() ===
            new Date("1900-01-01").getTime()
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = formMaster.length;
      //Get The Total Pages
      let temp = 0
      for (let i = 0; i < formMaster.length; i++) {

        if (
          new Date(formMaster[i].effDateTo).getTime() ===
          new Date("9999-12-31").getTime()
        ) {
          temp += 1
        }
      }
      total_pages.current = Math.ceil(
        temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (formMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < formMaster.length; i++) {
          if (
            new Date(formMaster[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
            temp_display_array.push(formMaster[i]);
          }
        }
      } else {
        if (
          formMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          formMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < formMaster.length;
            i++
          ) {
            if (
              new Date(formMaster[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(formMaster[i]);
            }
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            if (
              new Date(formMaster[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(formMaster[i]);
            }
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };


  const FetchTheData = (_fetch_link, _action, _type) => {
    if (_type === "FormMaster") {
      setLoading(true);
      let temp_forms_master = [];
      let temp_draft_array = [];
      let tempNonDraftArray = [];
      axios
        .get(_fetch_link)
        .then((response) => {
          if (response.status === 200) {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${clientSideValidations.find((e1) => e1.validationId == 356)
                  ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 276)
                  ?.validationCode
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${clientSideValidations.find((e1) => e1.validationId == 389)
                  ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
            let temp_count = 0;
            for (let i = 0; i < response.data.length; i++) {
              temp_forms_master.push(response.data[i]);
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime() &&
                response.data[i].formDbId != 1 &&
                response.data[i].formDbId != 2
              ) {
                tempNonDraftArray.push(response.data[i]);
                temp_count++;
              }
              setFormMasterArray(tempNonDraftArray);
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("1900-01-01").getTime() &&
                response.data[i].formDbId != 1 &&
                response.data[i].formDbId != 2
              ) {
                temp_draft_array.push(response.data[i]);
              }
            }
            set_draft_array(temp_draft_array);

            dispatch(populate_Form(temp_forms_master));
            //Get The Total Records
            total_records.current = temp_count;
            //Get The Total Pages
            total_pages.current = Math.ceil(
              temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
            );
            //Pagination Of All Records
            let temp_display_array = [];
            if (
              tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current = 1;
              for (let i = 0; i < tempNonDraftArray.length; i++) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              if (
                tempNonDraftArray.length <=
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
              ) {
                current_page.current -= 1;
              }
              if (
                tempNonDraftArray.length <=
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
              ) {
                for (
                  let i =
                    current_page.current *
                    process.env.REACT_APP_ITEMS_PER_PAGE -
                    process.env.REACT_APP_ITEMS_PER_PAGE;
                  i < tempNonDraftArray.length;
                  i++
                ) {
                  temp_display_array.push(tempNonDraftArray[i]);
                }
              } else {
                for (
                  let i =
                    current_page.current *
                    process.env.REACT_APP_ITEMS_PER_PAGE -
                    process.env.REACT_APP_ITEMS_PER_PAGE;
                  i <
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                  i++
                ) {
                  temp_display_array.push(tempNonDraftArray[i]);
                }
              }
            }
            //  let updatedTempdisplayArray=  temp_display_array.splice(temp_display_array.findIndex((e)=>e.formDbId==1 && e.formDbId==2),1)
            setPaginatedArray(temp_display_array);
            setLoading(false);
          } else {
            if (_action === "Get") {
              ShowMEWSPopup("⚠" + response.message, "Warning");
              set_mews_popup_color("#FFC107");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup(error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
          setLoading(false);
        });
    }
    if (_type === "FormSectionAttributeMaster") {
      let temp_FormSectionAttributeMaster = [];
      axios
        .get(_fetch_link)
        .then((response) => {
          if (response.status === 200) {
            if (_action === "Get") {
              ShowMEWSPopup("✅Successfully fetched the records", "Success");
              set_mews_popup_color("#28A745");
            }
            for (let i = 0; i < response.data.length; i++) {
              temp_FormSectionAttributeMaster.push(response.data[i]);
            }
            dispatch(
              populate_form_Section_Attribute(temp_FormSectionAttributeMaster)
            );
          } else {
            if (_action === "Get") {
              ShowMEWSPopup("⚠" + response.message, "Warning");
              set_mews_popup_color("#FFC107");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup(error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const [
    displayOldEditFormSectionAttribute,
    setdisplayOldEditFormSectionAttribute,
  ] = useState([]);
  const [displayViewFormSectionAttribute, setDisplayViewFormSectionAttribute] =
    useState([]);
  const [selectedformDbId, setSelectedformDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (formDbId, type) => {
    setSelectedformDbId(formDbId);
    console.log(formDbId);
    let tempViewFilterFormSection = formSectionAttribute.filter(
      (e) => e.formDbId == formDbId
    );
    console.log(tempViewFilterFormSection);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        setDisplayViewFormSectionAttribute(tempViewFilterFormSection);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        setdisplayOldEditFormSectionAttribute(tempViewFilterFormSection);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(formDbId);
  };
  const SupplyValuesToModal = (formDbId) => {
    let dateFrom = formMaster.find((e) => e.formDbId == formDbId).effDateFrom;
    let dateTo = formMaster.find((e) => e.formDbId == formDbId).effDateTo;
    setFormsObject((formsObject) => ({
      ...formsObject,
      formCode: formMaster.find((e) => e.formDbId == formDbId).formCode,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      formCode: formMaster.find((e) => e.formDbId == formDbId).formCode,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      formName: formMaster.find((e) => e.formDbId == formDbId).formName,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      formName: formMaster.find((e) => e.formDbId == formDbId).formName,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      version: formMaster.find((e) => e.formDbId == formDbId).version,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      version: formMaster.find((e) => e.formDbId == formDbId).version,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      versionDate: formMaster.find((e) => e.formDbId == formDbId).versionDate,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      versionDate: formMaster.find((e) => e.formDbId == formDbId).versionDate,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      description: formMaster.find((e) => e.formDbId == formDbId).description,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      description: formMaster.find((e) => e.formDbId == formDbId).description,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      instructions: formMaster.find((e) => e.formDbId == formDbId).instructions,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      instructions: formMaster.find((e) => e.formDbId == formDbId).instructions,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      effDateFrom: dateFrom,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      effDateFrom: dateFrom,
    }));
    setFormsObject((formsObject) => ({
      ...formsObject,
      effDateTo: dateTo,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      effDateTo: dateTo,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      createdBy: formMaster.find((e) => e.formDbId == formDbId).createdBy,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      updatedBy: formMaster.find((e) => e.formDbId == formDbId).updatedBy,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      createdTimestamp: formMaster.find((e) => e.formDbId == formDbId)
        .createdTimestamp,
    }));
    setFormsObjectDelete((formsObjectDelete) => ({
      ...formsObjectDelete,
      updatedTimestamp: formMaster.find((e) => e.formDbId == formDbId)
        .updatedTimestamp,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortForms = [...formMasterArray];
    if (sortStyle === "Descending") {
      let updatedFormsMaster = SortForms.sort((a, b) => {
        const a_res = action === "Code" ? a.formCode : a.formName;
        const b_res = action === "Code" ? b.formCode : b.formName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedFormsMaster);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let updatedFormsMaster = SortForms.sort((a, b) => {
        const a_res = action === "Code" ? a.formCode : a.formName;
        const b_res = action === "Code" ? b.formCode : b.formName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedFormsMaster);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortForms.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortForms[i]);
      }
    } else {
      for (let i = 0; i < SortForms.length; i++) {
        tempArray.push(SortForms[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortForms.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setFormMasterArray(SortForms);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };

  const [formSectionAttributeMasterArray, setFormSectionAttributeMasterArray] =
    useState([
      {
        attributeName: "",
        attributeValue: "",
        attributeValueType: "",
        attributeDisplayCol: 0,
        attributeDisplayRow: 0,
        sectionDisplayPercentage: 0,
        displayControl: "",
        controlLength: "",
        effDateFrom: new Date(),
        effDateTo: new Date("9999-12-31"),
        createdTimestamp: new Date(),
        createdBy: "",
        updatedBy: "",
        updatedTimestamp: new Date(),
      },
    ]);

  const OnClickSubForm = () => {
    let temp = [...formSectionAttributeMasterArray];
    temp.push({
      idx: formSectionAttributeMasterArray.length + 1,
      attributeName: "",
      attributeValue: "",
      attributeValueType: "",
      attributeDisplayCol: 0,
      attributeDisplayRow: 0,
      sectionDisplayPercentage: 0,
      displayControl: "",
      controlLength: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdTimestamp: new Date(),
      createdBy: "",
      updatedBy: "",
      updatedTimestamp: new Date(),
    });
    setFormSectionAttributeMasterArray(temp);
    OnClickFormSection();
  };
  const RemoveAddTheSubForm = (index) => {
    let temp = [...formSectionAttributeMasterArray];
    temp.splice(index, 1);
    setFormSectionAttributeMasterArray(temp);
  };
  const RemoveEditTheSubForm = (index) => {
    let temp = [...displayNewAddFormSectionAttribute];
    temp.splice(index, 1);
    setdisplayNewAddFormSectionAttribute(temp);
  };
  const AddTheFormsSectionItem = (formDbId) => {
    console.log("formDb" + formDbId);
    for (let i = 0; i < formSectionAttributeMasterArray.length; i++) {
      let data = {
        attributeName: formSectionAttributeMasterArray[i].attributeName,
        attributeValue: formSectionAttributeMasterArray[i].attributeValue,
        attributeValueType:
          formSectionAttributeMasterArray[i].attributeValueType,
        attributeDisplayCol: Number(
          formSectionAttributeMasterArray[i].attributeDisplayCol
        ),
        attributeDisplayRow: Number(
          formSectionAttributeMasterArray[i].attributeDisplayRow
        ),
        displayControl: formSectionAttributeMasterArray[i].displayControl,
        controlLength: Number(formSectionAttributeMasterArray[i].controlLength),
        formDbId: formDbId,
        sectionName: "Header",
        sectionDisplayPercentage: 20,
        sectionSeq: 1,
        effDateFrom: formsObject.effDateFrom,
        effDateTo: formsObject.effDateTo,
        createdBy: isAuthenticated == true ? user.name : null,
        createdTimestamp: new Date().toISOString(),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      console.log(data);
      axios
        .post(
          process.env.REACT_APP_BASE_API_PYTHON + "formSectionAttributeMaster",
          data
        )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "formSectionAttributeMaster?size=1000",

              "FormSectionAttributeMaster",
              "Post"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000",

              "FormMaster",
              "Post"
            );

            set_open_add_modal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
  };
  const [
    displayNewAddFormSectionAttribute,
    setdisplayNewAddFormSectionAttribute,
  ] = useState([
    {
      attributeName: "",
      attributeValue: "",
      attributeValueType: "",
      attributeDisplayCol: 0,
      attributeDisplayRow: 0,
      displayControl: "",
      controlLength: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdTimestamp: new Date(),
      createdBy: "",
      updatedBy: "",
      updatedTimestamp: new Date(),
    },
  ]);
  const UpdateTheFormsSectionItem = (formDbId, idx) => {
    for (let i = 0; i < displayOldEditFormSectionAttribute.length; i++) {
      let data = {
        attributeName: displayOldEditFormSectionAttribute[i].attributeName,
        attributeValue: displayOldEditFormSectionAttribute[i].attributeValue,
        attributeValueType:
          displayOldEditFormSectionAttribute[i].attributeValueType,
        attributeDisplayCol: Number(
          displayOldEditFormSectionAttribute[i].attributeDisplayCol
        ),
        attributeDisplayRow: Number(
          displayOldEditFormSectionAttribute[i].attributeDisplayRow
        ),
        displayControl: displayOldEditFormSectionAttribute[i].displayControl,
        controlLength: Number(
          displayOldEditFormSectionAttribute[i].controlLength
        ),
        formDbId: formDbId,
        sectionName: "Header",
        sectionDisplayPercentage: 20,
        sectionSeq: 1,
        effDateFrom: GetServerDate(formsObject.effDateFrom),
        effDateTo: GetServerDate(formsObject.effDateTo),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      axios
        .patch(
          `${process.env.REACT_APP_BASE_API_PYTHON}formSectionAttributeMaster/${displayOldEditFormSectionAttribute[i].formSectionAttributeDbId}`,
          data
        )
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
    for (let i = 0; i < displayNewAddFormSectionAttribute.length; i++) {
      let data = {
        attributeName: displayNewAddFormSectionAttribute[i].attributeName,
        attributeValue: displayNewAddFormSectionAttribute[i].attributeValue,
        attributeValueType:
          displayNewAddFormSectionAttribute[i].attributeValueType,
        attributeDisplayCol: Number(
          displayNewAddFormSectionAttribute[i].attributeDisplayCol
        ),
        attributeDisplayRow: Number(
          displayNewAddFormSectionAttribute[i].attributeDisplayRow
        ),
        displayControl: displayNewAddFormSectionAttribute[i].displayControl,
        controlLength: Number(
          displayNewAddFormSectionAttribute[i].controlLength
        ),
        formDbId: formDbId,
        sectionName: "Header",
        sectionDisplayPercentage: 20,
        sectionSeq: 1,
        effDateFrom: GetServerDate(formsObject.effDateFrom),
        effDateTo: GetServerDate(formsObject.effDateTo),
        createdBy: isAuthenticated == true ? user.name : null,
        createdTimestamp: new Date().toISOString(),
        updatedBy: isAuthenticated == true ? user.name : null,
        updatedTimestamp: new Date().toISOString(),
      };
      console.log(data);
      axios
        .post(
          process.env.REACT_APP_BASE_API_PYTHON + "formSectionAttributeMaster",
          data
        )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "formSectionAttributeMaster?size=1000",

              "FormSectionAttributeMaster",
              "Post"
            );
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "formMaster?size=1000",

              "FormMaster",
              "Post"
            );
            set_open_add_modal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
        });
    }
  };
  const ResetTheValue = (_action, idx) => {
    switch (_action) {
      case "Add":
        let temp = [...formSectionAttributeMasterArray];
        temp.find((e) => e.idx == idx).attributeName = "";
        temp.find((e) => e.idx == idx).attributeValue = "";
        temp.find((e) => e.idx == idx).attributeValueType = "";
        temp.find((e) => e.idx == idx).attributeDisplayCol = "";
        temp.find((e) => e.idx == idx).attributeDisplayRow = "";
        temp.find((e) => e.idx == idx).controlLength = "";
        temp.find((e) => e.idx == idx).displayControl = "";
        setFormSectionAttributeMasterArray(temp);
        break;
    }
  };
  const [attributeDisplayColError, setAttributeDisplayColError] = useState("");
  const [attributeDisplayRowNumberError, setAttributeDisplayRowNumberError] =
    useState("");
  const [attributeDisplayColNumberError, setAttributeDisplayColNumberError] =
    useState("");

  const onChangeFormSectionValue = (e, type, idx) => {
    let temp = [...formSectionAttributeMasterArray];
    switch (type) {
      case "attributeName":
        temp.find((e) => e.idx == idx).attributeName = e.target.value;
        break;
      case "attributeValue":
        temp.find((e) => e.idx == idx).attributeValue = e.target.value;
        break;
      case "attributeValueType":
        temp.find((e) => e.idx == idx).attributeValueType = e.target.value;
        if (e.target.value != "variable") {
          temp.find((e) => e.idx == idx).attributeValue = "";
        }
        break;
      case "attributeDisplayCol":
        let index1 = temp.findIndex(
          (e1) =>
            e1.attributeDisplayCol == e.target.value &&
            e1.attributeDisplayRow == temp[idx].attributeDisplayRow
        );
        if (index1 != -1) {
          temp[idx].attributeDisplayCol = 0;
          setAttributeDisplayColError("Duplicate Value");
        }
        if (e.target.value - idx != 1) {
          setAttributeDisplayColNumberError("Invliad Format");
        } else {
          setAttributeDisplayColNumberError("");
          setAttributeDisplayColError("");
          temp[idx].attributeDisplayCol = e.target.value;
          setFormSectionAttributeMasterArray(temp);
        }

        break;
      case "attributeDisplayRow":
        let index = temp.findIndex(
          (e1) =>
            e1.attributeDisplayRow == e.target.value &&
            e1.attributeDisplayCol == temp[idx].attributeDisplayCol
        );
        if (index != -1) {
          temp[idx].attributeDisplayRow = 0;
          setAttributeDisplayColError("Duplicate Value");
        }
        if (e.target.value - idx != 1) {
          setAttributeDisplayRowNumberError("Invliad Format");
        } else {
          setAttributeDisplayColError("");
          setAttributeDisplayRowNumberError("");
          temp[idx].attributeDisplayRow = e.target.value;
          setFormSectionAttributeMasterArray(temp);
        }

        break;
      case "displayControl":
        temp.find((e) => e.idx == idx).displayControl = e.target.value;
        break;
      case "controlLength":
        temp.find((e) => e.idx == idx).controlLength = e.target.value;
        break;
      case "sectionDisplayPercentage":
        temp.find((e) => e.idx == idx).sectionDisplayPercentage =
          e.target.value;
        break;
    }
    setFormSectionAttributeMasterArray(temp);
  };
  const [attributeEditDisplayColError, setAttributeEditDisplayColError] =
    useState("");
  const onChangeFormPostSectionValue = (e, type, idx) => {
    // eslint-disable-next-line default-case
    let temp = [...displayNewAddFormSectionAttribute];
    switch (type) {
      case "AttributeName":
        temp.find((e) => e.idx == idx).attributeName = e.target.value;
        break;
      case "AttributeValue":
        temp.find((e) => e.idx == idx).attributeValue = e.target.value;

        break;
      case "AttributeValueType":
        temp.find((e) => e.idx == idx).attributeValueType = e.target.value;
        if (e.target.value != "variable") {
          temp.find((e) => e.idx == idx).attributeValue = "";
        }
        break;
      case "AttributeDisplayCol":
        let index1 = temp.findIndex(
          (e1) =>
            e1.attributeDisplayCol == e.target.value &&
            e1.attributeDisplayRow == temp[idx].attributeDisplayRow
        );
        if (index1 != -1) {
          temp[idx].attributeDisplayCol = 0;
          setAttributeEditDisplayColError("Duplicate Value");
        } else {
          setAttributeEditDisplayColError("");
          temp[idx].attributeDisplayCol = e.target.value;
          setdisplayNewAddFormSectionAttribute(temp);
        }
        break;
      case "AttributeDisplayRow":
        let index = temp.findIndex(
          (e1) =>
            e1.attributeDisplayRow == e.target.value &&
            e1.attributeDisplayCol == temp[idx].attributeDisplayCol
        );
        if (index != -1) {
          temp[idx].attributeDisplayRow = 0;
          setAttributeEditDisplayColError("Duplicate Value");
        } else {
          setAttributeEditDisplayColError("");
          temp[idx].attributeDisplayRow = e.target.value;
          setdisplayNewAddFormSectionAttribute(temp);
        }
        break;
      case "DisplayControl":
        temp.find((e) => e.idx == idx).displayControl = e.target.value;
        break;
      case "ControlLength":
        temp.find((e) => e.idx == idx).controlLength = e.target.value;
        break;
      case "SectionDisplayPercentage":
        temp.find((e) => e.idx == idx).sectionDisplayPercentage =
          e.target.value;
        break;
    }
    setdisplayNewAddFormSectionAttribute(temp);
  };
  const OnChangeEditModalValues = (purpose, e, index) => {
    let tempFunction = [];
    for (let i = 0; i < displayOldEditFormSectionAttribute.length; i++) {
      tempFunction.push(displayOldEditFormSectionAttribute[i]);
    }
    let newObj = {
      approvedBy: tempFunction[index].approvedBy,
      approvedTimestamp: tempFunction[index].approvedTimestamp,
      attributeDisplayCol: tempFunction[index].attributeDisplayCol,
      attributeDisplayRow: tempFunction[index].attributeDisplayRow,
      attributeName: tempFunction[index].attributeName,
      attributeValue: tempFunction[index].attributeValue,
      attributeValueType: tempFunction[index].attributeValueType,
      controlLength: tempFunction[index].controlLength,
      createdBy: tempFunction[index].createdBy,
      createdTimestamp: tempFunction[index].createdTimestamp,
      displayControl: tempFunction[index].displayControl,
      formSectionAttributeDbId: tempFunction[index].formSectionAttributeDbId,
      formDbId: tempFunction[index].formDbId,
      effDateFrom: tempFunction[index].effDateFrom,
      effDateTo: tempFunction[index].effDateTo,
      sectionDisplayPercentage: tempFunction[index].sectionDisplayPercentage,
      sectionName: tempFunction[index].sectionName,
      sectionSeq: tempFunction[index].sectionSeq,
      updatedBy: tempFunction[index].updatedBy,
      updatedTimestamp: tempFunction[index].updatedTimestamp,
    };
    switch (purpose) {
      case "AttributeName":
        newObj["attributeName"] = e.target.value;
        break;
      case "AttributeValue":
        newObj["attributeValue"] = e.target.value;
        break;
      case "AttributeValueType":
        newObj["attributeValueType"] = e.target.value;
        break;
      case "AttributeDisplayCol":
        newObj["attributeDisplayCol"] = e.target.value;
        break;
      case "AttributeDisplayRow":
        newObj["attributeDisplayRow"] = e.target.value;
        break;
      case "SectionName":
        newObj["sectionName"] = e.target.value;
        break;
      case "DisplayControl":
        newObj["displayControl"] = e.target.value;
        break;
      case "ControlLength":
        newObj["controlLength"] = e.target.value;
        break;
      case "SectionDisplayPercentage":
        newObj["sectionDisplayPercentage"] = e.target.value;
        break;
      case "SectionSeq":
        newObj["sectionSeq"] = e.target.value;
        break;
    }
    tempFunction[index] = newObj;
    setdisplayOldEditFormSectionAttribute(tempFunction);
  };
  const handleAddModelClose = () => {
    set_open_add_modal(false);
    setOpen_Sub_Form(false);
  };
  const OnEditSubForm = () => {
    let temp = [...displayNewAddFormSectionAttribute];
    temp.push({
      idx: displayNewAddFormSectionAttribute.length + 1,
      attributeName: "",
      attributeValue: "",
      attributeValueType: "",
      attributeDisplayCol: 0,
      attributeDisplayRow: 0,
      sectionDisplayPercentage: 0,
      displayControl: "",
      controlLength: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdTimestamp: new Date(),
      createdBy: "",
      updatedBy: "",
      updatedTimestamp: new Date(),
    });
    setdisplayNewAddFormSectionAttribute(temp);
  };
  const deleteurl =
    process.env.REACT_APP_BASE_API_PYTHON + "formSectionAttributeMaster";

  const RemoveTheFormSectionAttribute = (_action, index) => {
    console.log("action" + _action);
    axios
      .patch(deleteurl + "/" + _action, {
        updatedTimestamp: new Date().toISOString(),
        effDateTo: OneBack(),
        updatedBy: isAuthenticated == true ? user.name : "",
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("put:" + response.status);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
    let temp = [...displayOldEditFormSectionAttribute];
    temp.splice(index, 1);
    setdisplayOldEditFormSectionAttribute(temp);
  };
  const EditFormFilled = (index) => {
    let tempCount = 0;
    if (displayNewAddFormSectionAttribute[index].attributeName.length == 0) {
      tempCount += 1;
    }
    if (displayNewAddFormSectionAttribute[index].attributeValue.length == 0) {
      tempCount += 1;
    }
    if (
      displayNewAddFormSectionAttribute[index].attributeValueType.length == 0
    ) {
      tempCount += 1;
    }
    if (displayNewAddFormSectionAttribute[index].attributeDisplayRow == 0) {
      tempCount += 1;
    }
    if (displayNewAddFormSectionAttribute[index].attributeDisplayCol == 0) {
      tempCount += 1;
    }
    if (displayNewAddFormSectionAttribute[index].displayControl.length == 0) {
      tempCount += 1;
    }
    if (displayNewAddFormSectionAttribute[index].controlLength == 0) {
      tempCount += 1;
    }
    return tempCount;
  };
  const FormScreenCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);
  const [showFormSectionToolTip, setShowFormSectionToolTip] = useState(0);

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-col w-full ">
        {isAuthenticated == true ? (
          <div>
            <Header />
            <div className="container-fluid">
              <SuccessMessage
                mews_popup_color={mews_popup_color}
                open_edit_modal={open_edit_modal}
                show_mews_popup={show_mews_popup}
                multiple_codes={multiple_codes}
                mews_message={mews_message}
              />
              {open_add_modal == false &&
                open_edit_modal == false &&
                open_view_modal == false ? (
                <>
                  <ManageSearch
                    name="Forms"
                    OnClickShowDrafts={OnClickShowDrafts}
                    show_drafts={show_drafts}
                    toolTipName={
                      screensToolTips.find((e) => e.toolTipDbId == 431)
                        ?.toolTipDesc
                    }
                    search_content={search_content}
                    onChangeSearchContent={SearchContent}
                    placeholder="Forms"
                  />
                  <div className="tablerounds">
                    <div className="tableboxes">
                      <table>
                        <colgroup>
                          <col span="1" style={{ width: "4%" }} />
                          <col span="1" style={{ width: "22%" }} />
                          <col span="1" style={{ width: "30%" }} />
                          <col span="1" style={{ width: "8%" }} />
                          <col span="1" style={{ width: "12%" }} />
                          <col span="1" style={{ width: "8%" }} />
                          <col span="1" style={{ width: "8%" }} />
                          <col span="1" style={{ width: "8%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>Sl.</th>
                            <th>
                              <div className="IconsSpace">
                                <img
                                  onClick={() =>
                                    AscendingDescendingFunction("Code")
                                  }
                                  className="ArrowIcons"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                                Form Code
                                <img
                                  className="ArrowIcons opacity-0"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                              </div>
                            </th>
                            <th>
                              <div className="IconsSpace">
                                <img
                                  onClick={() =>
                                    AscendingDescendingFunction("Name")
                                  }
                                  className="ArrowIcons"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                                Form Name
                                <img
                                  className="ArrowIcons opacity-0"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                              </div>
                            </th>
                            <th>Version</th>
                            <th>Version Date</th>
                            <th>Effective From</th>
                            <th>Effective To</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {show_drafts === false && search_content.length === 0
                            ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.formDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {item.formCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {item.formName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {item.version}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {/* {item.versionDate} */}
                                  {item.versionDate != null
                                    ? new Date(
                                      item.versionDate
                                    ).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(
                                        item.versionDate
                                      ).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(
                                      item.versionDate
                                    ).getUTCFullYear()
                                    : null}
                                </td>
                                {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.formDbId)}>{item.description}</td>
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.formDbId)}>{item.instructions}</td>  */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(
                                          item.effDateFrom
                                        ).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(
                                      item.effDateFrom
                                    ).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.formDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.formDbId}
                                  />
                                </td>
                              </tr>
                            ))
                            : show_drafts === true
                              ? display_draft_array.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.formDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {item.formCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {item.formName}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {item.version}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {item.versionDate != null
                                      ? new Date(
                                        item.versionDate
                                      ).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(
                                          item.versionDate
                                        ).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(
                                        item.versionDate
                                      ).getUTCFullYear()
                                      : null}
                                  </td>
                                  {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.formDbId)}>{item.description}</td>
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.formDbId)}>{item.instructions}</td>  */}
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.effDateFrom
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.formDbId)
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <IconComponent
                                      multiple_codes={multiple_codes}
                                      OpenModal={OpenModal}
                                      ShowDeleteConfirmation={
                                        ShowDeleteConfirmation
                                      }
                                      selectedFormDbId={item.formDbId}
                                    />
                                  </td>
                                </tr>
                              ))
                              : search_content.length > 0
                                ? paginatedArray.map((item, index) => (
                                  <tr
                                    style={{
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.formDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    key={index}
                                  >
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {index +
                                        1 +
                                        process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                    </td>
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {item.formCode}
                                    </td>
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {item.formName}
                                    </td>
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {item.version}
                                    </td>
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {item.versionDate != null
                                        ? new Date(
                                          item.versionDate
                                        ).getUTCDate() +
                                        "-" +
                                        (Number(
                                          new Date(
                                            item.versionDate
                                          ).getUTCMonth()
                                        ) +
                                          1) +
                                        "-" +
                                        new Date(
                                          item.versionDate
                                        ).getUTCFullYear()
                                        : null}
                                    </td>
                                    {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.formDbId)}>{item.description}</td>
                                <td style={{borderColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.formDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.formDbId)}>{item.instructions}</td>  */}
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {ReturnThePaddedValues(
                                        new Date(item.effDateFrom).getUTCDate()
                                      ) +
                                        "-" +
                                        ReturnThePaddedValues(
                                          Number(
                                            new Date(
                                              item.effDateFrom
                                            ).getUTCMonth()
                                          ) + 1
                                        ) +
                                        "-" +
                                        new Date(
                                          item.effDateFrom
                                        ).getUTCFullYear()}
                                    </td>
                                    <td
                                      style={{
                                        borderColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#FFFFFF"
                                            : "#DAE0E4",
                                        backgroundColor:
                                          multiple_codes.findIndex(
                                            (e) => e === item.formDbId
                                          ) !== -1
                                            ? "#DAE0E4"
                                            : "#FFFFFF",
                                      }}
                                      onClick={() =>
                                        select_deselect_the_record(item.formDbId)
                                      }
                                    >
                                      {new Date(item.effDateTo).getUTCDate() +
                                        "-" +
                                        (Number(
                                          new Date(item.effDateTo).getUTCMonth()
                                        ) +
                                          1) +
                                        "-" +
                                        new Date(item.effDateTo).getUTCFullYear()}
                                    </td>
                                    <td>
                                      <IconComponent
                                        multiple_codes={multiple_codes}
                                        OpenModal={OpenModal}
                                        ShowDeleteConfirmation={
                                          ShowDeleteConfirmation
                                        }
                                        selectedFormDbId={item.formDbId}
                                      />
                                    </td>
                                  </tr>
                                ))
                                : null}
                        </tbody>
                      </table>
                      <div className="flex flex-col items-center justify-center">
                        <p>
                          {" "}
                          {paginatedArray.length == 0 ? "No records found" : ""}
                        </p>
                      </div>
                      <div />
                    </div>
                    <TableButton
                      current_page={current_page.current}
                      total_pages={total_pages.current}
                      onPressPrevious={onPressPrevious}
                      onPressNext={onPressNext}
                      array={paginatedArray}
                      screenCode={
                        FormScreenCode.find((e) => e.screenId === 108)
                          ?.screenCode
                      }
                      onClickAddItem={onClickAddItem}
                      OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                      multiple_codes_delete={multiple_codes.length > 1}
                    />
                  </div>
                </>
              ) : null}
              {/* //View box */}
              {open_view_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>View Forms Details</div>
                    <div>
                      {
                        FormScreenCode.find((e) => e.screenId === 110)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="flex flex-col gap-5">
                      <div className="additionalHeader">
                        View The Form Master
                      </div>
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Form Code</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={formsObject.formCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Form Name</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={formsObject.formName}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Version</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={formsObject.version}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Version Date</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(formsObject.versionDate).getUTCDate() +
                              "-" +
                              (Number(
                                new Date(formsObject.versionDate).getUTCMonth()
                              ) +
                                1) +
                              "-" +
                              new Date(formsObject.versionDate).getUTCFullYear()
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Description</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={formsObject.description}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Instructions</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={formsObject.instructions}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              Eff. Date From
                            </label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(
                                formsObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    formsObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(formsObject.effDateFrom).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">Eff. Date To</label>
                          </div>
                          <input
                            type="text"
                            disabled={true}
                            value={
                              new Date(formsObject.effDateTo).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(formsObject.effDateTo).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(formsObject.effDateTo).getUTCDate()
                              )
                            }
                            className="child_input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="additionalHeader">View The Header</div>
                    <div className="flex flex-col gap-5">
                      {displayViewFormSectionAttribute.map((item, index) => (
                        <div className="flex flex-col gap-4 border-dark-default border-dotted p-2 border-2">
                          <div className="inputGrid">
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Name
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={item.attributeName}
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  {" "}
                                  Attribute Value
                                </label>
                              </div>

                              <input
                                disabled={true}
                                onChange={(e) =>
                                  OnChangeEditModalValues(
                                    "AttributeValue",
                                    e,
                                    index
                                  )
                                }
                                value={
                                  item.attributeValue === "$customer"
                                    ? "Customer"
                                    : item.attributeValue === "$facility_name"
                                      ? "Facility Name"
                                      : item.attributeValue === "$floor"
                                        ? "Floor"
                                        : item.attributeValue === "$form_name"
                                          ? "Form Name"
                                          : item.attributeValue === "$insp_date"
                                            ? "Inspection Date"
                                            : item.attributeValue === "$line"
                                              ? "Line"
                                              : item.attributeValue === "$module"
                                                ? "Module"
                                                : item.attributeValue === "$product"
                                                  ? "Product Name"
                                                  : item.attributeValue === "$qa_process_code"
                                                    ? "Qulity Process"
                                                    : item.attributeValue === "$shift"
                                                      ? "Shift"
                                                      : item.attributeValue === "VIN: $vin"
                                                        ? "VIN"
                                                        : null
                                }
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Value Type
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={
                                  item.attributeValueType === "formula"
                                    ? "Formula"
                                    : item.attributeValueType === "variable"
                                      ? " Platform Variable"
                                      : item.attributeValueType === "sql"
                                        ? "SQL"
                                        : item.attributeValueType === "static"
                                          ? "Static"
                                          : item.attributeValueType === "input"
                                            ? "User Input"
                                            : null
                                }
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Display Row
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={item.attributeDisplayRow}
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Attribute Display Col
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={item.attributeDisplayCol}
                                className="child_input"
                              />
                            </div>

                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Display Control
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={
                                  item.displayControl === "barcode"
                                    ? "Barcode"
                                    : item.displayControl === "camera"
                                      ? "Camera"
                                      : item.displayControl === "checkbox"
                                        ? "Checkbox"
                                        : item.displayControl === "dropdown"
                                          ? "Dropdown"
                                          : item.displayControl === "formula"
                                            ? "Formula"
                                            : item.displayControl ===
                                              "incrementdecrement"
                                              ? "Increment Decrement"
                                              : item.displayControl === " longnumberbox"
                                                ? "Long Numberbox"
                                                : item.displayControl === "paragraph"
                                                  ? "Paragraph"
                                                  : item.displayControl === "radiobutton"
                                                    ? "Radio Button"
                                                    : item.displayControl === "subheading"
                                                      ? "Subheading"
                                                      : item.displayControl === "text"
                                                        ? "Textbox"
                                                        : null
                                }
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Control Length
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={item.controlLength}
                                className="child_input"
                              />
                            </div>

                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Eff. Date From
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={
                                  new Date(
                                    formsObject.effDateFrom
                                  ).getUTCFullYear() +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(
                                        formsObject.effDateFrom
                                      ).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    new Date(
                                      formsObject.effDateFrom
                                    ).getUTCDate()
                                  )
                                }
                                className="child_input"
                              />
                            </div>
                            <div className="parent_popup">
                              <div className="child_label_flex">
                                <label className="child_popup">
                                  Eff. Date To
                                </label>
                              </div>
                              <input
                                type="text"
                                disabled={true}
                                value={
                                  new Date(
                                    formsObject.effDateTo
                                  ).getUTCFullYear() +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(
                                        formsObject.effDateTo
                                      ).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    new Date(formsObject.effDateTo).getUTCDate()
                                  )
                                }
                                className="child_input"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ViewPageButton
                    close_modal={set_open_view_modal}
                    OpenModal={OpenModal}
                    selectedDbId={selectedformDbId}
                  />
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>Edit Forms Details</div>
                    <div>
                      {
                        FormScreenCode.find((e) => e.screenId === 111)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="flex flex-col gap-5">
                      <div className="additionalHeader">
                        Edit the Form Master
                      </div>
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 226
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.formCodeError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 9}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 226)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(9)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.formCode}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 224
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.formNameError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 10}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 224)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(10)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.formName}
                            onChange={(e) => onChangeValue(e, "formName", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 222
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.versionError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 11}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 222)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(11)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.version}
                            onChange={(e) => onChangeValue(e, "version", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 220
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.versionDateError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 12}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 220)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(12)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.versionDate}
                            onChange={(e) =>
                              onChangeValue(e, "versionDate", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 218
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.descriptionError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 13}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 218)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(13)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.description}
                            onChange={(e) =>
                              onChangeValue(e, "description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 216
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.instructionsError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 14}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 216)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(14)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.instructions}
                            onChange={(e) =>
                              onChangeValue(e, "instructions", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 214
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.effDateFromError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 15}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 214)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(15)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="date"
                            value={
                              new Date(
                                formsObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    formsObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(formsObject.effDateFrom).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 212
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.effDateToError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 16}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 212)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(16)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="date"
                            value={
                              new Date(formsObject.effDateTo).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(formsObject.effDateTo).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(formsObject.effDateTo).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-5">
                      <div className="additionalHeader">Edit the Header</div>
                      <div className="flex flex-col gap-5">
                        {displayOldEditFormSectionAttribute.map(
                          (item, index) => (
                            <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default">
                              <div className="inputGrid">
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 400
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 10}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 400
                                      )?.toolTipDesc
                                    }
                                  />
                                  <input
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(10)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    type="text"
                                    value={item.attributeName}
                                    onChange={(e) =>
                                      OnChangeEditModalValues(
                                        "AttributeName",
                                        e,
                                        index
                                      )
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {" "}
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 404
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 11}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 404
                                      )?.toolTipDesc
                                    }
                                  />
                                  <select
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(11)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    className="child_input"
                                    value={item.attributeValueType}
                                    onChange={(e) =>
                                      OnChangeEditModalValues(
                                        "AttributeValueType",
                                        e,
                                        index
                                      )
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="formula">Formula</option>
                                    <option value="variable">
                                      Platform Variable
                                    </option>
                                    <option value="sql">SQL</option>
                                    <option value="static">Static</option>
                                    <option value="input">User Input</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {" "}
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 402
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  {item.attributeValueType === "variable" ? (
                                    <>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 12
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 402
                                          )?.toolTipDesc
                                        }
                                      />
                                      <select
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(12)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        value={item.attributeValue}
                                        onChange={(e) =>
                                          OnChangeEditModalValues(
                                            "AttributeValue",
                                            e,
                                            index
                                          )
                                        }
                                        className="child_input"
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="$customer">
                                          Customer
                                        </option>
                                        <option value="$facility_name">
                                          Facility Name
                                        </option>
                                        <option value="$floor">Floor</option>
                                        <option value="$form_name">
                                          Form Name
                                        </option>
                                        <option value="$insp_date">
                                          Inspection Date
                                        </option>
                                        <option value="$line">Line</option>
                                        <option value="$module">Module</option>
                                        <option value="$product">
                                          Product Name
                                        </option>
                                        <option value="$qa_process_code">
                                          Qulity Process
                                        </option>
                                        <option value="$shift">Shift</option>
                                        <option value="VIN: $vin">VIN</option>
                                      </select>
                                    </>
                                  ) : (
                                    <>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 12
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 402
                                          )?.toolTipDesc
                                        }
                                      />
                                      <input
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(12)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        type="text"
                                        value={item.attributeValue}
                                        onChange={(e) =>
                                          OnChangeEditModalValues(
                                            "AttributeValue",
                                            e,
                                            index
                                          )
                                        }
                                        className="child_input"
                                      />
                                    </>
                                  )}
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 406
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 13}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 406
                                      )?.toolTipDesc
                                    }
                                  />
                                  <input
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(13)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    className="child_input"
                                    value={item.attributeDisplayRow}
                                    onChange={(e) =>
                                      OnChangeEditModalValues(
                                        "AttributeDisplayRow",
                                        e,
                                        index
                                      )
                                    }
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 408
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 14}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 408
                                      )?.toolTipDesc
                                    }
                                  />
                                  <select
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(14)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    className="child_input"
                                    value={item.attributeDisplayCol}
                                    onChange={(e) =>
                                      OnChangeEditModalValues(
                                        "AttributeDisplayCol",
                                        e,
                                        index
                                      )
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                  </select>
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 410
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 15}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 410
                                      )?.toolTipDesc
                                    }
                                  />
                                  <select
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(15)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    className="child_input"
                                    value={item.displayControl}
                                    onChange={(e) =>
                                      OnChangeEditModalValues(
                                        "DisplayControl",
                                        e,
                                        index
                                      )
                                    }
                                  >
                                    <option value={null}>Select...</option>
                                    <option value="barcode">Barcode</option>
                                    <option value="camera">Camera</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="dropdown">Dropdown</option>
                                    <option value="formula">Formula</option>
                                    <option value="incrementdecrement">
                                      Increment Decrement
                                    </option>
                                    <option value="longnumberbox">
                                      Long Numberbox
                                    </option>
                                    <option value="paragraph">Paragraph</option>
                                    <option value="radiobutton">
                                      Radio Button
                                    </option>
                                    <option value="subheading">
                                      Subheading
                                    </option>
                                    <option value="textbox">Textbox</option>
                                  </select>
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 412
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 16}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 412
                                      )?.toolTipDesc
                                    }
                                  />
                                  <input
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(16)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    type="text"
                                    value={item.controlLength}
                                    onChange={(e) =>
                                      OnChangeEditModalValues(
                                        "ControlLength",
                                        e,
                                        index
                                      )
                                    }
                                    className="child_input"
                                  />
                                </div>

                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 414
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 17}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 414
                                      )?.toolTipDesc
                                    }
                                  />
                                  <input
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(17)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    type="date"
                                    value={
                                      new Date(
                                        formsObject.effDateFrom
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            formsObject.effDateFrom
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          formsObject.effDateFrom
                                        ).getUTCDate()
                                      )
                                    }
                                    onChange={(e) =>
                                      onChangeValue(e, "effDateFrom", null)
                                    }
                                    className="child_input"
                                  />
                                </div>
                                <div className="parent_popup">
                                  <div className="child_label_flex">
                                    <label className="child_popup">
                                      {
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 416
                                        )?.labelName
                                      }
                                    </label>
                                    <label className="error"></label>
                                  </div>
                                  <InputToolTip
                                    showTooltip={showFormSectionToolTip == 18}
                                    name={
                                      screensToolTips.find(
                                        (e) => e.toolTipDbId == 416
                                      )?.toolTipDesc
                                    }
                                  />
                                  <input
                                    onPointerEnter={() =>
                                      setShowFormSectionToolTip(18)
                                    }
                                    onPointerLeave={() =>
                                      setShowFormSectionToolTip(0)
                                    }
                                    type="date"
                                    value={
                                      new Date(
                                        formsObject.effDateTo
                                      ).getUTCFullYear() +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            formsObject.effDateTo
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        new Date(
                                          formsObject.effDateTo
                                        ).getUTCDate()
                                      )
                                    }
                                    onChange={(e) =>
                                      onChangeValue(e, "effDateTo", null)
                                    }
                                    className="child_input"
                                  />
                                </div>
                              </div>
                              <div className="flex  flex-row items-center justify-center">
                                <div className="flex flex-row gap-5">
                                  {/* {displayOldEditFormSectionAttribute.length >
                                      1 && ( */}
                                  <img
                                    onClick={() =>
                                      RemoveTheFormSectionAttribute(
                                        item.formSectionAttributeDbId,
                                        index
                                      )
                                    }
                                    className="Icons"
                                    src={DeleteIcon}
                                  />
                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="flex lg:flex-row flex-col additionalHeader items-center justify-center gap-3 py-5">
                        <div className="additionalHeader">
                          Do you want to add header details?
                        </div>
                        <div className="flex flex-row items-center gap-5">
                          <div
                            className="flex flex-row items-center gap-1 cursor-pointer"
                            onClick={() => OnClickFormSection()}
                          >
                            <p>Yes</p>
                            <img
                              className="Icons"
                              src={
                                open_Sub_Form === true
                                  ? RadioOnIcon
                                  : RadioOffIcon
                              }
                            />
                          </div>
                          <div
                            onClick={() => setOpen_Sub_Form(false)}
                            className="flex flex-row items-center gap-1 cursor-pointer"
                          >
                            <p>No</p>
                            <img
                              className="Icons"
                              src={
                                open_Sub_Form === false
                                  ? RadioOnIcon
                                  : RadioOffIcon
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {open_Sub_Form === true ? (
                        <div className="flex flex-col gap-5">
                          {displayNewAddFormSectionAttribute.map(
                            (item, index) => (
                              <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default">
                                <div className="inputGrid">
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 399
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 1}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 399
                                        )?.toolTipDesc
                                      }
                                    />
                                    <input
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(1)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      type="text"
                                      value={
                                        displayNewAddFormSectionAttribute.find(
                                          (e) => e.idx == item.idx
                                        ).attributeName
                                      }
                                      onChange={(e) =>
                                        onChangeFormPostSectionValue(
                                          e,
                                          "AttributeName",
                                          item.idx
                                        )
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 403
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 2}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 403
                                        )?.toolTipDesc
                                      }
                                    />
                                    <select
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(2)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      className="child_input"
                                      value={
                                        displayNewAddFormSectionAttribute.find(
                                          (e) => e.idx == item.idx
                                        ).attributeValueType
                                      }
                                      onChange={(e) =>
                                        onChangeFormPostSectionValue(
                                          e,
                                          "AttributeValueType",
                                          item.idx
                                        )
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="formula">Formula</option>
                                      <option value="variable">
                                        Platform Variable
                                      </option>
                                      <option value="sql">SQL</option>
                                      <option value="static">Static</option>
                                      <option value="input">User Input</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 401
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    {item.attributeValueType === "variable" ? (
                                      <>
                                        <InputToolTip
                                          showTooltip={
                                            showFormSectionToolTip == 3
                                          }
                                          name={
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 401
                                            )?.toolTipDesc
                                          }
                                        />
                                        <select
                                          onPointerEnter={() =>
                                            setShowFormSectionToolTip(3)
                                          }
                                          onPointerLeave={() =>
                                            setShowFormSectionToolTip(0)
                                          }
                                          value={
                                            displayNewAddFormSectionAttribute.find(
                                              (e) => e.idx == item.idx
                                            ).attributeValue
                                          }
                                          onChange={(e) =>
                                            onChangeFormPostSectionValue(
                                              e,
                                              "AttributeValue",
                                              item.idx
                                            )
                                          }
                                          className="child_input"
                                        >
                                          <option value={null}>
                                            Select...
                                          </option>
                                          <option value="$customer">
                                            Customer
                                          </option>
                                          <option value="$facility_name">
                                            Facility Name
                                          </option>
                                          <option value="$floor">Floor</option>
                                          <option value="$form_name">
                                            Form Name
                                          </option>
                                          <option value="$insp_date">
                                            Inspection Date
                                          </option>
                                          <option value="$line">Line</option>
                                          <option value="$module">
                                            Module
                                          </option>
                                          <option value="$product">
                                            Product Name
                                          </option>
                                          <option value="$qa_process_code">
                                            Qulity Process
                                          </option>
                                          <option value="$shift">Shift</option>
                                          <option value="VIN: $vin">VIN</option>
                                        </select>
                                      </>
                                    ) : (
                                      <>
                                        <InputToolTip
                                          showTooltip={
                                            showFormSectionToolTip == 3
                                          }
                                          name={
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 401
                                            )?.toolTipDesc
                                          }
                                        />
                                        <input
                                          onPointerEnter={() =>
                                            setShowFormSectionToolTip(3)
                                          }
                                          onPointerLeave={() =>
                                            setShowFormSectionToolTip(0)
                                          }
                                          type="text"
                                          value={
                                            displayNewAddFormSectionAttribute.find(
                                              (e) => e.idx == item.idx
                                            ).attributeValue
                                          }
                                          onChange={(e) =>
                                            onChangeFormPostSectionValue(
                                              e,
                                              "AttributeValue",
                                              item.idx
                                            )
                                          }
                                          className="child_input"
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 405
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error">
                                        {attributeEditDisplayColError}
                                      </label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 4}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 405
                                        )?.toolTipDesc
                                      }
                                    />
                                    <input
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(4)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      className="child_input"
                                      value={
                                        displayNewAddFormSectionAttribute.find(
                                          (e) => e.idx == item.idx
                                        ).attributeDisplayRow
                                      }
                                      onChange={(e) =>
                                        onChangeFormPostSectionValue(
                                          e,
                                          "AttributeDisplayRow",
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 407
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 5}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 407
                                        )?.toolTipDesc
                                      }
                                    />
                                    <select
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(5)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      className="child_input"
                                      value={
                                        displayNewAddFormSectionAttribute.find(
                                          (e) => e.idx == item.idx
                                        ).attributeDisplayCol
                                      }
                                      onChange={(e) =>
                                        onChangeFormPostSectionValue(
                                          e,
                                          "AttributeDisplayCol",
                                          index
                                        )
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                    </select>
                                  </div>

                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 409
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 6}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 409
                                        )?.toolTipDesc
                                      }
                                    />
                                    <select
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(6)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      className="child_input"
                                      value={
                                        displayNewAddFormSectionAttribute.find(
                                          (e) => e.idx == item.idx
                                        ).displayControl
                                      }
                                      onChange={(e) =>
                                        onChangeFormPostSectionValue(
                                          e,
                                          "DisplayControl",
                                          item.idx
                                        )
                                      }
                                    >
                                      <option value={null}>Select...</option>
                                      <option value="barcode">Barcode</option>
                                      <option value="camera">Camera</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="dropdown">Dropdown</option>
                                      <option value="formula">Formula</option>
                                      <option value="incrementdecrement">
                                        Increment Decrement
                                      </option>
                                      <option value="longnumberbox">
                                        Long Numberbox
                                      </option>
                                      <option value="paragraph">
                                        Paragraph
                                      </option>
                                      <option value="radiobutton">
                                        Radio Button
                                      </option>
                                      <option value="subheading">
                                        Subheading
                                      </option>
                                      <option value="textbox">Textbox</option>
                                    </select>
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 411
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 7}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 411
                                        )?.toolTipDesc
                                      }
                                    />
                                    <input
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(7)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      type="text"
                                      value={
                                        displayNewAddFormSectionAttribute.find(
                                          (e) => e.idx == item.idx
                                        ).controlLength
                                      }
                                      onChange={(e) =>
                                        onChangeFormPostSectionValue(
                                          e,
                                          "ControlLength",
                                          item.idx
                                        )
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 413
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 8}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 413
                                        )?.toolTipDesc
                                      }
                                    />
                                    <input
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(8)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      type="date"
                                      value={
                                        new Date(
                                          formsObject.effDateFrom
                                        ).getUTCFullYear() +
                                        "-" +
                                        ReturnThePaddedValues(
                                          Number(
                                            new Date(
                                              formsObject.effDateFrom
                                            ).getUTCMonth()
                                          ) + 1
                                        ) +
                                        "-" +
                                        ReturnThePaddedValues(
                                          new Date(
                                            formsObject.effDateFrom
                                          ).getUTCDate()
                                        )
                                      }
                                      onChange={(e) =>
                                        onChangeValue(e, "effDateFrom", null)
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                  <div className="parent_popup">
                                    <div className="child_label_flex">
                                      <label className="child_popup">
                                        {
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 415
                                          )?.labelName
                                        }
                                      </label>
                                      <label className="error"></label>
                                    </div>
                                    <InputToolTip
                                      showTooltip={showFormSectionToolTip == 9}
                                      name={
                                        screensToolTips.find(
                                          (e) => e.toolTipDbId == 415
                                        )?.toolTipDesc
                                      }
                                    />
                                    <input
                                      onPointerEnter={() =>
                                        setShowFormSectionToolTip(9)
                                      }
                                      onPointerLeave={() =>
                                        setShowFormSectionToolTip(0)
                                      }
                                      type="date"
                                      value={
                                        new Date(
                                          formsObject.effDateTo
                                        ).getUTCFullYear() +
                                        "-" +
                                        ReturnThePaddedValues(
                                          Number(
                                            new Date(
                                              formsObject.effDateTo
                                            ).getUTCMonth()
                                          ) + 1
                                        ) +
                                        "-" +
                                        ReturnThePaddedValues(
                                          new Date(
                                            formsObject.effDateTo
                                          ).getUTCDate()
                                        )
                                      }
                                      onChange={(e) =>
                                        onChangeValue(e, "effDateTo", null)
                                      }
                                      className="child_input"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-center">
                                  <div className="flex flex-row gap-5">
                                    {EditFormFilled(index) == 0 ? (
                                      <img
                                        onClick={() => OnEditSubForm()}
                                        className="Icons"
                                        src={PlusIcon}
                                      />
                                    ) : null}
                                    <img
                                      onClick={() =>
                                        RemoveEditTheSubForm(index)
                                      }
                                      className="Icons"
                                      src={DeleteIcon}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <EditPageButton
                    close_modal={set_open_edit_modal}
                    EditTheItem={OnEditForms}
                  />
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations ? (
                <DeleteConfirmationModal
                  CancelTheDeletion={CancelTheDeletion}
                  OnDeleteItem={OnDeleteItem}
                  item_to_delete={item_to_delete}
                />
              ) : null}

              {/* //Add Popup column */}
              {open_add_modal === true ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>Add Forms Details</div>
                    <div>
                      {
                        FormScreenCode.find((e) => e.screenId === 109)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="flex flex-col gap-5">
                      <div className="additionalHeader">Add the Form</div>
                      <div className="inputGrid">
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 225
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.formCodeError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 1}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 225)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(1)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.formCode}
                            onChange={(e) => onChangeValue(e, "formCode", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 223
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.formNameError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 2}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 223)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(2)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.formName}
                            onChange={(e) => onChangeValue(e, "formName", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 221
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.versionError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 3}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 221)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(3)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.version}
                            onChange={(e) => onChangeValue(e, "version", null)}
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 219
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.versionDateError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 4}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 219)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(4)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.versionDate}
                            onChange={(e) =>
                              onChangeValue(e, "versionDate", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 217
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.descriptionError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 5}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 217)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(5)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.description}
                            onChange={(e) =>
                              onChangeValue(e, "description", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 215
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.instructionsError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 6}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 215)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(6)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="text"
                            value={formsObject.instructions}
                            onChange={(e) =>
                              onChangeValue(e, "instructions", null)
                            }
                            className="child_input"
                          />
                        </div>

                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 213
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.effDateFromError}
                            </label>
                          </div>

                          <InputToolTip
                            showTooltip={showTooltip == 7}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 213)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(7)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="date"
                            value={
                              new Date(
                                formsObject.effDateFrom
                              ).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(
                                    formsObject.effDateFrom
                                  ).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(formsObject.effDateFrom).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateFrom", null)
                            }
                            className="child_input"
                          />
                        </div>
                        <div className="parent_popup">
                          <div className="child_label_flex">
                            <label className="child_popup">
                              {
                                screensToolTips.find(
                                  (e) => e.toolTipDbId == 211
                                )?.labelName
                              }
                            </label>
                            <label className="error">
                              {formsObjectErrors.effDateToError}
                            </label>
                          </div>
                          <InputToolTip
                            showTooltip={showTooltip == 8}
                            name={
                              screensToolTips.find((e) => e.toolTipDbId == 211)
                                ?.toolTipDesc
                            }
                          />
                          <input
                            onPointerEnter={() => setShowTooltip(8)}
                            onPointerLeave={() => setShowTooltip(0)}
                            type="date"
                            value={
                              new Date(formsObject.effDateTo).getUTCFullYear() +
                              "-" +
                              ReturnThePaddedValues(
                                Number(
                                  new Date(formsObject.effDateTo).getUTCMonth()
                                ) + 1
                              ) +
                              "-" +
                              ReturnThePaddedValues(
                                new Date(formsObject.effDateTo).getUTCDate()
                              )
                            }
                            onChange={(e) =>
                              onChangeValue(e, "effDateTo", null)
                            }
                            className="child_input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex lg:flex-row flex-col items-center justify-center gap-3 py-5 ">
                      <div className="additionalHeader">
                        Do you want to add header details?
                      </div>
                      <div className="flex flex-row items-center justify-center gap-5">
                        <div
                          className="flex flex-row items-center justify-center gap-1"
                          onClick={() => OnClickFormSection()}
                        >
                          <p>Yes</p>
                          <img
                            className="Icons"
                            src={
                              open_Sub_Form === true
                                ? RadioOnIcon
                                : RadioOffIcon
                            }
                          />
                        </div>
                        <div
                          onClick={() => setOpen_Sub_Form(false)}
                          className="flex flex-row items-center justify-center gap-1"
                        >
                          <p>No</p>
                          <img
                            className="Icons"
                            src={
                              open_Sub_Form === false
                                ? RadioOnIcon
                                : RadioOffIcon
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div ref={bottomRef}>
                      {open_Sub_Form === true ? (
                        <div className="flex flex-col gap-5" ref={bottomRef}>
                          <div className="additionalHeader">Add the Header</div>
                          <div className="flex flex-col gap-5">
                            {formSectionAttributeMasterArray.map(
                              (item, index) => (
                                <div className="flex flex-col gap-4 border-dotted border-2 p-2 border-dark-default">
                                  <div className="inputGrid">
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 399
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 1
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 399
                                          )?.toolTipDesc
                                        }
                                      />
                                      <input
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(1)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        type="text"
                                        value={
                                          formSectionAttributeMasterArray.find(
                                            (e) => e.idx == item.idx
                                          ).attributeName
                                        }
                                        onChange={(e) =>
                                          onChangeFormSectionValue(
                                            e,
                                            "attributeName",
                                            item.idx
                                          )
                                        }
                                        className="child_input"
                                      />
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 403
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 2
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 403
                                          )?.toolTipDesc
                                        }
                                      />
                                      <select
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(2)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        className="child_input"
                                        value={
                                          formSectionAttributeMasterArray.find(
                                            (e) => e.idx == item.idx
                                          ).attributeValueType
                                        }
                                        onChange={(e) =>
                                          onChangeFormSectionValue(
                                            e,
                                            "attributeValueType",
                                            item.idx
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="formula">Formula</option>
                                        <option value="variable">
                                          Platform Variable
                                        </option>
                                        <option value="sql">SQL</option>
                                        <option value="static">Static</option>
                                        <option value="input">
                                          User Input
                                        </option>
                                      </select>
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {" "}
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 401
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      {item.attributeValueType ===
                                        "variable" ? (
                                        <>
                                          <InputToolTip
                                            showTooltip={
                                              showFormSectionToolTip == 3
                                            }
                                            name={
                                              screensToolTips.find(
                                                (e) => e.toolTipDbId == 401
                                              )?.toolTipDesc
                                            }
                                          />
                                          <select
                                            onPointerEnter={() =>
                                              setShowFormSectionToolTip(3)
                                            }
                                            onPointerLeave={() =>
                                              setShowFormSectionToolTip(0)
                                            }
                                            value={
                                              formSectionAttributeMasterArray.find(
                                                (e) => e.idx == item.idx
                                              ).attributeValue
                                            }
                                            onChange={(e) =>
                                              onChangeFormSectionValue(
                                                e,
                                                "attributeValue",
                                                item.idx
                                              )
                                            }
                                            className="child_input"
                                          >
                                            <option value={null}>
                                              Select...
                                            </option>
                                            <option value="$customer">
                                              Customer
                                            </option>
                                            <option value="$facility_name">
                                              Facility Name
                                            </option>
                                            <option value="$floor">
                                              Floor
                                            </option>
                                            <option value="$form_name">
                                              Form Name
                                            </option>
                                            <option value="$insp_date">
                                              Inspection Date
                                            </option>
                                            <option value="$line">Line</option>
                                            <option value="$module">
                                              Module
                                            </option>
                                            <option value="$product">
                                              Product Name
                                            </option>
                                            <option value="$qa_process_code">
                                              Qulity Process
                                            </option>
                                            <option value="$shift">
                                              Shift
                                            </option>
                                            <option value="VIN: $vin">
                                              VIN
                                            </option>
                                          </select>
                                        </>
                                      ) : (
                                        <>
                                          <InputToolTip
                                            showTooltip={
                                              showFormSectionToolTip == 3
                                            }
                                            name={
                                              screensToolTips.find(
                                                (e) => e.toolTipDbId == 403
                                              )?.toolTipDesc
                                            }
                                          />
                                          <input
                                            onPointerEnter={() =>
                                              setShowFormSectionToolTip(3)
                                            }
                                            onPointerLeave={() =>
                                              setShowFormSectionToolTip(0)
                                            }
                                            type="text"
                                            value={
                                              formSectionAttributeMasterArray.find(
                                                (e) => e.idx == item.idx
                                              ).attributeValue
                                            }
                                            onChange={(e) =>
                                              onChangeFormSectionValue(
                                                e,
                                                "attributeValue",
                                                item.idx
                                              )
                                            }
                                            className="child_input"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 405
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error">
                                          {attributeDisplayColError}
                                          {attributeDisplayRowNumberError}
                                        </label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 4
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 405
                                          )?.toolTipDesc
                                        }
                                      />
                                      <input
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(4)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        inputMode="numeric"
                                        className="child_input"
                                        value={
                                          formSectionAttributeMasterArray.find(
                                            (e) => e.idx == item.idx
                                          ).attributeDisplayRow
                                        }
                                        onChange={(e) =>
                                          onChangeFormSectionValue(
                                            e,
                                            "attributeDisplayRow",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 407
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error">
                                          {attributeDisplayColError}
                                          {attributeDisplayColNumberError}
                                        </label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 5
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 407
                                          )?.toolTipDesc
                                        }
                                      />
                                      <select
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(5)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        className="child_input"
                                        value={
                                          formSectionAttributeMasterArray.find(
                                            (e) => e.idx == item.idx
                                          ).attributeDisplayCol
                                        }
                                        onChange={(e) =>
                                          onChangeFormSectionValue(
                                            e,
                                            "attributeDisplayCol",
                                            index
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                      </select>
                                    </div>

                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {" "}
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 409
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 6
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 409
                                          )?.toolTipDesc
                                        }
                                      />
                                      <select
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(6)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        className="child_input"
                                        value={
                                          formSectionAttributeMasterArray.find(
                                            (e) => e.idx == item.idx
                                          ).displayControl
                                        }
                                        onChange={(e) =>
                                          onChangeFormSectionValue(
                                            e,
                                            "displayControl",
                                            item.idx
                                          )
                                        }
                                      >
                                        <option value={null}>Select...</option>
                                        <option value="barcode">Barcode</option>
                                        <option value="camera">Camera</option>
                                        <option value="checkbox">
                                          Checkbox
                                        </option>
                                        <option value="dropdown">
                                          Dropdown
                                        </option>
                                        <option value="formula">Formula</option>
                                        <option value="incrementdecrement">
                                          Increment Decrement
                                        </option>
                                        <option value="longnumberbox">
                                          Long Numberbox
                                        </option>
                                        <option value="paragraph">
                                          Paragraph
                                        </option>
                                        <option value="radiobutton">
                                          Radio Button
                                        </option>
                                        <option value="subheading">
                                          Subheading
                                        </option>
                                        <option value="textbox">Textbox</option>
                                      </select>
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 411
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 7
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 411
                                          )?.toolTipDesc
                                        }
                                      />
                                      <input
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(7)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        type="text"
                                        value={
                                          formSectionAttributeMasterArray.find(
                                            (e) => e.idx == item.idx
                                          ).controlLength
                                        }
                                        onChange={(e) =>
                                          onChangeFormSectionValue(
                                            e,
                                            "controlLength",
                                            item.idx
                                          )
                                        }
                                        className="child_input"
                                      />
                                    </div>

                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 413
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 8
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 413
                                          )?.toolTipDesc
                                        }
                                      />
                                      <input
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(8)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        type="date"
                                        value={
                                          new Date(
                                            formsObject.effDateFrom
                                          ).getUTCFullYear() +
                                          "-" +
                                          ReturnThePaddedValues(
                                            Number(
                                              new Date(
                                                formsObject.effDateFrom
                                              ).getUTCMonth()
                                            ) + 1
                                          ) +
                                          "-" +
                                          ReturnThePaddedValues(
                                            new Date(
                                              formsObject.effDateFrom
                                            ).getUTCDate()
                                          )
                                        }
                                        onChange={(e) =>
                                          onChangeValue(e, "effDateFrom", null)
                                        }
                                        className="child_input"
                                      />
                                    </div>
                                    <div className="parent_popup">
                                      <div className="child_label_flex">
                                        <label className="child_popup">
                                          {
                                            screensToolTips.find(
                                              (e) => e.toolTipDbId == 415
                                            )?.labelName
                                          }
                                        </label>
                                        <label className="error"></label>
                                      </div>
                                      <InputToolTip
                                        showTooltip={
                                          showFormSectionToolTip == 9
                                        }
                                        name={
                                          screensToolTips.find(
                                            (e) => e.toolTipDbId == 415
                                          )?.toolTipDesc
                                        }
                                      />
                                      <input
                                        onPointerEnter={() =>
                                          setShowFormSectionToolTip(9)
                                        }
                                        onPointerLeave={() =>
                                          setShowFormSectionToolTip(0)
                                        }
                                        type="date"
                                        value={
                                          new Date(
                                            formsObject.effDateTo
                                          ).getUTCFullYear() +
                                          "-" +
                                          ReturnThePaddedValues(
                                            Number(
                                              new Date(
                                                formsObject.effDateTo
                                              ).getUTCMonth()
                                            ) + 1
                                          ) +
                                          "-" +
                                          ReturnThePaddedValues(
                                            new Date(
                                              formsObject.effDateTo
                                            ).getUTCDate()
                                          )
                                        }
                                        onChange={(e) =>
                                          onChangeValue(e, "effDateTo", null)
                                        }
                                        className="child_input"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center justify-center">
                                    <div className="flex flex-row gap-5">
                                      {isFormFilled(index) == 0 ? (
                                        <img
                                          onClick={() => OnClickSubForm()}
                                          className="Icons"
                                          src={PlusIcon}
                                        />
                                      ) : null}

                                      <img
                                        onClick={() =>
                                          RemoveAddTheSubForm(index)
                                        }
                                        className="Icons"
                                        src={DeleteIcon}
                                      />
                                      {/* )} */}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <AddPageButton
                    close_modal={set_open_add_modal}
                    AddTheItem={AddTheItem}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {loading == true ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Forms;
