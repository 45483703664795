import { createSlice } from "@reduxjs/toolkit";
export const BomSlice = createSlice({
  name: "bom",
  initialState: {
    value: [],
  },
  reducers: {
    populate_Bom: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_Bom } = BomSlice.actions;
export default BomSlice.reducer;
