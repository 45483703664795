import React from "react";
import ViewIcon from "../Assert/View.png";
import EditIcon from "../Assert/Edit.png";
import DeleteIcon from "../Assert/Delete.png";

function IconComponent(props) {
  return (
    <div className="anotherIconsSpace">
      <img
        className="Icons"
        onClick={() => props.OpenModal(props.selectedFormDbId, "View")}
        src={ViewIcon}
        alt=""
      />
      {props.multiple_codes.length == 1 &&
      props.multiple_codes.findIndex((e) => e === props.selectedFormDbId) !==
        -1 ? (
        <img
          className={`Icons`}
          onClick={() => props.OpenModal(props.selectedFormDbId, "Edit")}
          src={EditIcon}
          alt=""
        />
      ) : (
        <img className={`Icons opacity-0 cursor-auto`} src={EditIcon} alt="" />
      )}
      {props.multiple_codes.findIndex((e) => e === props.selectedFormDbId) !==
      -1 ? (
        <img
          className={`Icons opacity-100`}
          onClick={() => props.ShowDeleteConfirmation(props.selectedFormDbId)}
          src={DeleteIcon}
          alt=""
        />
      ) : (
        <img className={`Icons opacity-0 cursor-auto`} src={EditIcon} alt="" />
      )}
    </div>
  );
}

export default IconComponent;
