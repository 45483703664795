import React from "react";
import PreviousIcon from "../Assert/PreviousPage.png";
import PreviousHiddenIcon from "../Assert/PreviousHidden.png";
import NextHiddenIcon from "../Assert/NextHidden.png";
import NextIcon from "../Assert/NextPage.png";

function TableButton(props) {
  return (
    <div className="flex lg:flex-row flex-col lg:flex-nowrap flex-wrap items-center lg:justify-between justify-center gap-4">
      <div className="col-span-1 flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-1">
          <img
            src={props.current_page == 1 ? PreviousHiddenIcon : PreviousIcon}
            onClick={() => props.onPressPrevious()}
            className={`PreviouesNextIcons ${
              props.current_page == 1 ? "cursor-auto" : "cursor-pointer"
            }`}
            alt=""
          />
          <img
            src={
              props.current_page == props.total_pages
                ? NextHiddenIcon
                : NextIcon
            }
            onClick={() => props.onPressNext()}
            className={`PreviouesNextIcons ${
              props.current_page == props.total_pages
                ? "cursor-auto"
                : "cursor-pointer"
            }`}
            alt=""
          />
        </div>
        <p>
          Page {props.current_page} of{" "}
          {props.array.length === 0 ? props.current_page : props.total_pages}
        </p>
      </div>
      <div>
        <p>{"Screen ID : " + props.screenCode}</p>
      </div>
      <div className="col-span-1 flex flex-row items-center gap-5">
        <button className="addBtn" onClick={() => props.onClickAddItem()}>
          Add
        </button>
        <button className="uploadBtn">Upload</button>
        <button
          disabled={props.multiple_codes_delete ? false : true}
          className={`deleteBtn ${
            props.multiple_codes_delete ? "opacity-100" : "opacity-50"
          }`}
          onClick={() => props.OnDeleteSelectionClicked()}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default TableButton;
