import React, { useEffect, useRef, useState } from "react";
import StrateraLogo from "../Assert/StrateraLogo.png";
import StrateraAnotherLogo from "../Assert/StrateraAnotherLogo.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { populate_user } from "./Redux/UserSlice";
import { useAuth0 } from "@auth0/auth0-react";
import "../index.css";
import MessageIcon from "../Assert/Message.png";
import NotificationIcon from "../Assert/Notification.png";
import ProfileIcon from "../Assert/User.png";
import LogoutIcon from "../Assert/logout1.png";
import axios from "axios";
import { populate_role_Hierarchy } from "./Redux/RoleHierarchySlice";
import { populate_Staff } from "./Redux/StaffSlice";
import NotificationOrange from "../Assert/Bell.png";
import UserOrange from "../Assert/UserOrange.png";
import LogoutOrange from "../Assert/logoutOrange.png";
import MenuIcon from "../Assert/Menu.png";
import DropdownIcon from "../Assert/Dropdown1.png";
import DropupIcon from "../Assert/Dropup.png";
import CloseIcon from "../Assert/Close.png";
import DotsIcon from "../Assert/DotsIcon.png";
import NextIcon from "../Assert/NextPage.png";

function Header() {
  const { user, isAuthenticated } = useAuth0();
  const [isMastersSelected, setIsMastersSelected] = useState(false);
  const [isProcessesSelected, setIsProcessesSelected] = useState(false);
  const [isParametersSelected, setIsParametersSelected] = useState(false);
  const [isPeoplAccessSelected, setIsPeopleAccessSelected] = useState(false);
  const [isDashboardSelected, setIsDashboardSelected] = useState(false);
  const [isQualityIssuesSelected, setIsQualityIssuesSelected] = useState(false);
  const [isSelectedEvent, setIsSelectedEvent] = useState(false);
  const headerColor = useSelector((state) => state.callUser.value);
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const OnChangeSelectedOption = (action) => {
    dispatch(populate_user(action));
    setIsDashboardSelected(false);
    setIsMastersSelected(false);
    setIsProcessesSelected(false);
    setIsParametersSelected(false);
    setIsPeopleAccessSelected(false);
    setIsQualityIssuesSelected(false);
    setIsSelectedEvent(false);
    switch (action) {
      case "Masters":
        setIsMastersSelected(!isMastersSelected);
        break;
      case "Processes":
        setIsProcessesSelected(!isProcessesSelected);
        break;
      case "Parameters":
        setIsParametersSelected(!isParametersSelected);
        break;
      case "PeopleAccess":
        setIsPeopleAccessSelected(!isPeoplAccessSelected);
        break;
      case "Dashboard":
        setIsDashboardSelected(!isDashboardSelected);
        break;
      case "QualityIssue":
        setIsQualityIssuesSelected(!isQualityIssuesSelected);
        break;
      case "Events":
        setIsSelectedEvent(!isSelectedEvent);
        break;
      default:
        console.log("Undefined");
        break;
    }
  };
  const [currentTime, setCurrentTime] = useState(new Date());
  const iframeRef = useRef(null);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.id !== "Masters") {
        setIsMastersSelected(false);
      }
      if (e.target.id !== "Processes") {
        setIsProcessesSelected(false);
      }
      if (e.target.id !== "Parameters") {
        setIsParametersSelected(false);
      }
      if (e.target.id !== "PeopleAccess") {
        setIsPeopleAccessSelected(false);
      }
      if (e.target.id !== "Dashboard") {
        setIsDashboardSelected(false);
      }
      if (e.target.id !== "QualityIssue") {
        setIsQualityIssuesSelected(false);
      }
      if (e.target.id !== "Events") {
        setIsSelectedEvent(false);
      }
      if (e.target.id !== "DateTime") {
        setIsSelectedTime(false);
      }
      if (e.target.id !== "Sidebar") {
        setIsSelectedMobileNotification(false);
      }
    });
    // if (iframeRef.current) {
    //   iframeRef.current.contentWindow.document.addEventListener('click', (e) => {
    //     if (e.target.id !== "Masters") {
    //       setIsMastersSelected(false);
    //     }
    //   });
    // }
    setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    FetchTheStaff();
    FetchTheRole();
    // return () => clearInterval(inter);
  }, []);
  const onSelectTheOption = (_option, _route_name, _dashboard_link) => {
    if (_dashboard_link !== null) {
      setSelectedDashboard(_option);
      navigate("/" + _route_name, {
        state: { dashboard_link: _dashboard_link },
      });
      localStorage.setItem("header", _dashboard_link);
    } else {
      navigate("/" + _route_name);
      localStorage.setItem("header", _route_name);
    }
    setIsDashboardSelected(false);
    setIsMastersSelected(false);
    setIsProcessesSelected(false);
    setIsParametersSelected(false);
    setIsPeopleAccessSelected(false);
    setIsQualityIssuesSelected(false);
    setIsSelectedPaintsChemicals(false);
    setIsSupplierQualitySelected(false);
  };

  const [isSelectedPaintsChemicals, setIsSelectedPaintsChemicals] =
    useState(false);
  const [isSupplierQualitySelected, setIsSupplierQualitySelected] =
    useState(false);
  const [isTolerancesSelected, setIsTolerancesSelected] = useState(false);
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [isNotificationSelected, setIsNotificationSelected] = useState(false);
  const [isLogoutSelected, setIsLogoutSelected] = useState(false);

  const dispatch = useDispatch();
  const [isSelectedTime, setIsSelectedTime] = useState(false);

  const ReplaceTheMonth = (val) => {
    switch (val) {
      case 0:
        return "JAN";
      case 1:
        return "FEB";
      case 2:
        return "MAR";
      case 3:
        return "APR";
      case 4:
        return "MAY";
      case 5:
        return "JUN";
      case 6:
        return "JUL";
      case 7:
        return "AUG";
      case 8:
        return "SEPT";
      case 9:
        return "OCT";
      case 10:
        return "NOV";
      case 11:
        return "DEC";
      default:
        console.log("Undeefined");
        break;
    }
  };
  const ReturnTheHours = () => {
    if (new Date().getHours() > 12) {
      return "PM";
    } else {
      return "AM";
    }
  };
  const ReturnTheTwelveHoursFormat = () => {
    if (new Date(currentTime).getHours() === 0) {
      return 12;
    } else if (new Date(currentTime).getHours() > 12) {
      return Number(new Date(currentTime).getHours() - 12).length === 2
        ? Number(new Date(currentTime).getHours() - 12)
        : String(Number(new Date(currentTime).getHours() - 12)).padStart(2, 0);
    } else {
      return new Date().getHours();
    }
  };
  const ReturnThePaddedMinutes = () => {
    return Number(new Date().getMinutes()).length === 2
      ? Number(new Date().getMinutes())
      : String(Number(new Date().getMinutes())).padStart(2, 0);
  };
  const navigate = useNavigate();

  let roleHierarchyMaster = useSelector(
    (state) => state.callRoleHierarchy.value
  );
  let staff = useSelector((state) => state.callStaff.value);
  const FetchTheRole = () => {
    if (roleHierarchyMaster.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
          "roleHierarchyMaster?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempRoleHierarchyMaster = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempRoleHierarchyMaster.push(response.data[i]);
              }
            }
            dispatch(populate_role_Hierarchy(tempRoleHierarchyMaster));
          }
        });
    }
  };
  const FetchTheStaff = () => {
    if (staff.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "staff?size=1000")
        .then((response) => {
          if (response.status === 200) {
            let tempStaff = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                tempStaff.push(response.data[i]);
              }
            }

            dispatch(populate_Staff(tempStaff));
          }
        });
    }
  };

  const [isSelectedMobileMenu, setIsSelectedMobileMenu] = useState(false);
  const [isSelectedMobileNotitification, setIsSelectedMobileNotification] =
    useState(false);

  let Master = [
    {
      name: "Facility",
      _route_name: "Facility",
    },
    {
      name: "Product",
      _route_name: "Products",
    },
    {
      name: "Equipment",
      _route_name: "Equipment",
    },
    {
      name: "Sensor",
      _route_name: "Sensor",
    },
    {
      name: "Customer",
      _route_name: "Company",
    },
    {
      name: "Supplier",
      _route_name: "Supplier",
    },
    {
      name: "Part",
      _route_name: "Parts",
    },
    {
      name: "BOM",
      _route_name: "BOM",
    },
    {
      name: "Customer Product Map",
      _route_name: "CustomerProduct",
    },
    // {
    //   name: "Screens",
    //   _route_name: "Screens",
    // },
    {
      name: "Test Certficate",
      _route_name: "TestCertficate",
      // _dashboard_link: process.env.REACT_APP_CERTIFICATE_PORTAL_MASTER_URL,
    },
  ];
  let Processes = [
    {
      name: "Production Process",
      _route_name: "ProductionProcess",
    },
    {
      name: "Quality Process",
      _route_name: "QualityProcess",
    },
    {
      name: "Forms",
      _route_name: "Forms",
    },
    {
      name: "Test Certificate Validation",
      _route_name: "TestCertificateValidation",
      // _dashboard_link: process.env.REACT_APP_CERTIFICATE_PORTAL_VALIDATION_URL,
    },
  ];
  let Parameters = [
    {
      name: "Defect Dictionary",
      _route_name: "DefectCodes",
    },
    {
      name: "Failure Codes Dictionary",
      _route_name: "FailureCodes",
    },
    {
      name: " Severity Codes",
      _route_name: "SeverityCodes",
    },
  ];
  let PeopleAccess = [
    {
      name: "Role Hierarchy",
      _route_name: "RoleHierarchy",
    },
    {
      name: "Staff",
      _route_name: "Staff",
    },
    {
      name: "Inspection Roster",
      _route_name: "Roster",
    },
  ];

  let QualityIssue = [
    {
      name: "Customer Complaints",
      _route_name: "Complaint",
    },
  ];
  let Event = [
    {
      name: "Event Rules",
      _route_name: "EventRules",
    },
    {
      name: "Event Notifications",
      _route_name: "EventTriggers",
    },
  ];
  let Application = [
    {
      name: "Apps",
    },
    {
      name: "Queries",
    },
  ];
  let DashboardArray = [
    {
      name: "Automotive PBI",
      _route_name: "AutomotivePBI",
      _dirname: "dashboards",
      _dashboard_link:
        "https://app.powerbi.com/view?r=eyJrIjoiZWJkNTA3MWEtYjU3Ni00OGYxLWFmMWUtZTVkOTY0YWZlNGViIiwidCI6IjBiM2FlNjczLWQ3YzctNDdiNy05ZWI4LTA2MjZkOGZkOTU0YSJ9",
    },
    {
      name: "Automotive RT",
      _route_name: "AutomotiveRT",
      _dirname: "dashboards",
      _dashboard_link: process.env.REACT_APP_AUTOMOTIVE_RT_DASHBOARD_URL,
    },
    {
      name: "Extrusion Products PBI",
      _route_name: "ExtrusionProducts",
      _dirname: "dashboards",
      _dashboard_link:
        "https://app.powerbi.com/view?r=eyJrIjoiODYyMWNhNzItMDZiOS00ZWVjLTljZmYtMzg3NDg2MzViMjIyIiwidCI6IjBiM2FlNjczLWQ3YzctNDdiNy05ZWI4LTA2MjZkOGZkOTU0YSJ9",
    },
    {
      name: "Extrusion Products RT",
      _route_name: "ExtrusionProductsRt",
      _dirname: "dashboards",
      _dashboard_link:
        process.env.REACT_APP_EXTRUSION_PRODUCTS_RT_DASHBOARD_URL,
    },
  ];
  let PaintsChemicalsDashboardArray = [
    {
      name: "Product Quality",
      _route_name: "ProductQuality",
      _dirname: "dashboards",
      _dashboard_link: "https://app.powerbi.com/view?r=eyJrIjoiNzBhOWM4ZTItZWFmNS00OTk5LTgxNDEtMTE5NTY3MTkyOTVlIiwidCI6IjBiM2FlNjczLWQ3YzctNDdiNy05ZWI4LTA2MjZkOGZkOTU0YSJ9",
    },
    {
      name: "Machine Complaints",
      _route_name: "MachineComplaints",
      _dirname: "dashboards",
      _dashboard_link: "https://app.powerbi.com/view?r=eyJrIjoiMGFlNzUzOGItODhhMy00ODBmLWFjOTEtMzA0ZjdhZmM0MTNiIiwidCI6IjBiM2FlNjczLWQ3YzctNDdiNy05ZWI4LTA2MjZkOGZkOTU0YSJ9",
    },
  ];
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  return (
    <div className="w-full border-b-[1px] bg-white flex flex-col border-[#E7F5FF]">
      <div className="w-full lg:px-[1%] xl:px-[1%] px-[2%] h-[80px] flex flex-row items-center justify-between rounded-b-xl bg-banner bg-no-repeat bg-cover py-2">
        <div className="xl:w-4/12 flex flex-col  justify-start">
          <div className="lg:w-[69px] lg:h-[64px] w-[50px] h-[50px] bg-white border-[1px] border-[#FFFFFF] flex flex-col items-center justify-center rounded-[9px]">
            <img
              className="lg:w-[64px] lg:h-[49px] w-[45px] h-[45px] object-contain"
              src={StrateraLogo}
              alt=""
            />
          </div>
        </div>
        <div className="xl:w-4/12 flex flex-row items-center justify-center">
          <div className="text-white font-semibold xl:text-[1.875rem] lg:text-[1.875rem] md:text-[1.875rem] sm:text-[1.5rem] xs:text-[1.125rem] flex flex-row items-center justify-center">
            Digitize... Digitalize...&nbsp;<i>Fast</i>
          </div>
        </div>
        <div className="xl:w-4/12 flex flex-row items-center justify-end gap-5">
          <ul className=" flex-col 2xl:flex xl:flex lg:hidden hidden text-right text-white">
            <li className="bannerInfo">
              {new Date().getDate() +
                "-" +
                ReplaceTheMonth(new Date().getMonth()) +
                "-" +
                new Date().getFullYear() +
                " " +
                ReturnTheTwelveHoursFormat() +
                ":" +
                ReturnThePaddedMinutes() +
                " " +
                ReturnThePaddedValues(new Date().getUTCSeconds()) +
                " " +
                ReturnTheHours()}
              {/* + ":" +new Date().getUTCMilliseconds() */}
            </li>
            <li className="bannerInfo">Release : 3.8</li>
            <li className="bannerInfo">
              {" "}
              {isAuthenticated === true
                ? staff.findIndex((e) => e.workEmail == user.email) != -1
                  ? staff.find((e) => e.workEmail == user.email).firstName
                  : null
                : null}{" "}
              {isAuthenticated === true
                ? staff.findIndex((e) => e.workEmail == user.email) != -1
                  ? staff.find((e) => e.workEmail == user.email).lastName
                  : null
                : null}
            </li>
            <li className="bannerInfo">
              {" "}
              {isAuthenticated === true
                ? roleHierarchyMaster.findIndex(
                  (e) =>
                    e.roleDbId ==
                    staff.findIndex((e1) => e1.workEmail === user.email)
                ) != -1
                  ? roleHierarchyMaster.find(
                    (e) =>
                      e.roleDbId ==
                      staff.find((e1) => e1.workEmail === user.email).roleDbId
                  ).jobFunction
                  : null
                : null}
            </li>
          </ul>
          <img
            className="lg:w-[66px] lg:h-[66px] w-[45px] h-[45px] object-contain"
            id="DateTime"
            src={StrateraAnotherLogo}
            onClick={() => setIsSelectedTime(!isSelectedTime)}
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center justify-between lg:px-[1%] xl:px-[1%] px-[2%]">
        <div className="2xl:flex xl:flex lg:hidden hidden flex-row 2xl:gap-x-4 xl:gap-x-2 gap-x-2 overflow-x-auto">
          <div className="LinkHidden">Introduction</div>
          <div>
            <div
              id="Masters"
              style={{
                color: headerColor == "Masters" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "Masters"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className="menustyle"
              onClick={() => OnChangeSelectedOption("Masters")}
            >
              Masters▼
            </div>
            {isMastersSelected === true ? (
              <div className="header-menu">
                {Master.map((item, index) => (
                  <div
                    style={{
                      color: window.location.href.includes(item._route_name)
                        ? "#E85C00"
                        : "",
                      borderBottom: window.location.href.includes(
                        item._route_name
                      )
                        ? "3px solid #f27822"
                        : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        item.name,
                        item._route_name,
                        item._dashboard_link,
                      )
                    }
                    className="subMenustyle"
                    key={index}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            <div
              id="Processes"
              style={{
                color: headerColor === "Processes" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "Processes"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className="menustyle"
              onClick={() => OnChangeSelectedOption("Processes")}
            >
              Processes▼
            </div>
            {isProcessesSelected === true ? (
              <div className="header-menu">
                {Processes.map((item, index) => (
                  <div
                    style={{
                      color: window.location.href.includes(item._route_name)
                        ? "#E85C00"
                        : "",
                      borderBottom: window.location.href.includes(
                        item._route_name
                      )
                        ? "3px solid #f27822"
                        : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        item.name,
                        item._route_name,
                        item._dashboard_link,
                      )
                    }
                    className="subMenustyle"
                    key={index}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            <div
              id="Parameters"
              style={{
                color: headerColor === "Parameters" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "Parameters"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className="menustyle"
              onClick={() => OnChangeSelectedOption("Parameters")}
            >
              Parameters▼
            </div>
            {isParametersSelected === true ? (
              <div className="flex flex-col">
                <div className="header-menu">
                  {Parameters.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                        )
                      }
                      className="subMenustyle"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                  <div
                    id="Parameters"
                    onPointerEnter={() => setIsTolerancesSelected(true)}
                    onPointerLeave={() => setIsTolerancesSelected(false)}
                    onClick={() => setIsTolerancesSelected(true)}
                    className="Tolerances"
                    style={{
                      color: isTolerancesSelected === true ? "#E85C00" : "",
                    }}
                  >
                    <span id="Parameters">Tolerances</span>
                    <img className="Icons" src={NextIcon} />
                  </div>
                </div>

                {isTolerancesSelected === true ? (
                  <div className="menuSub mt-[143px]">
                    <label
                      style={{
                        color: window.location.href.includes(
                          "InspectionParameters"
                        )
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          "InspectionParameters"
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      className="subMenustyle"
                      onClick={() => {
                        onSelectTheOption(
                          "InspectionParameters",
                          "InspectionParameters",
                          null
                        );
                        setIsTolerancesSelected(false)
                      }
                      }
                      onMouseEnter={() => setIsTolerancesSelected(true)}
                      onMouseLeave={() => setIsTolerancesSelected(false)}
                    >
                      Inspection Parameters
                    </label>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div>
            <div
              id="PeopleAccess"
              style={{
                color: headerColor === "PeopleAccess" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "PeopleAccess"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className="menustyle"
              onClick={() => OnChangeSelectedOption("PeopleAccess")}
            >
              People & Access▼
            </div>
            {isPeoplAccessSelected === true ? (
              <div className="header-menu">
                {PeopleAccess.map((item, index) => (
                  <div
                    style={{
                      color: window.location.href.includes(item._route_name)
                        ? "#E85C00"
                        : "",
                      borderBottom: window.location.href.includes(
                        item._route_name
                      )
                        ? "3px solid #f27822"
                        : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        item.name,
                        item._route_name,
                        item._dashboard_link,
                        null
                      )
                    }
                    className="subMenustyle"
                    key={index}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            <div
              id="Dashboard"
              style={{
                color: headerColor === "Dashboard" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "Dashboard"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className={`menustyle`}
              onClick={() => OnChangeSelectedOption("Dashboard")}
            >
              Dashboards▼
            </div>

            {isDashboardSelected ? (
              <div className="header-menu z-10">
                {DashboardArray.map((item, index) => (
                  <label
                    key={index}
                    style={{
                      color:
                        selectedDashboard === item._route_name ? "#E85C00" : "",
                      borderBottom:
                        selectedDashboard === item._route_name
                          ? "3px solid #f27822"
                          : "",
                    }}
                    className="subMenustyle "
                    onClick={() =>
                      onSelectTheOption(
                        item._route_name,
                        item._dirname,
                        item._dashboard_link,
                        null
                      )
                    }
                  >
                    {item.name}
                  </label>
                ))}
                <div
                  id="Dashboard"
                  onPointerEnter={() => setIsSelectedPaintsChemicals(true)}
                  onPointerLeave={() => setIsSelectedPaintsChemicals(false)}
                  onClick={() => {
                    setIsSupplierQualitySelected(false);
                    setIsSelectedPaintsChemicals(true);
                  }}
                  className="Tolerances"
                  style={{
                    color: isSelectedPaintsChemicals ? "#E85C00" : "",
                  }}
                >
                  <label id="Dashboard">Paints & Chemicals</label>
                  <img className="Icons" src={NextIcon} />
                </div>
                <label
                  style={{
                    color: selectedDashboard === "NCC" ? "#E85C00" : "",
                    borderBottom:
                      selectedDashboard === "NCC" ? "3px solid #f27822" : "",
                  }}
                  onClick={() =>
                    onSelectTheOption(
                      "NCC",
                      "dashboards",
                      process.env.REACT_APP_NCC_DASHBOARD_URL
                    )
                  }
                  className="subMenustyle"
                >
                  NCC
                </label>

                <div
                  id="Dashboard"
                  onPointerEnter={() => setIsSupplierQualitySelected(true)}
                  onPointerLeave={() => setIsSupplierQualitySelected(false)}
                  onClick={() => {
                    setIsSelectedPaintsChemicals(false);
                    setIsSupplierQualitySelected(true);
                  }}
                  className="Tolerances"
                  style={{
                    color: isSupplierQualitySelected === true ? "#E85C00" : "",
                  }}
                >
                  <label id="Dashboard">Supplier Quality</label>
                  <img className="Icons" src={NextIcon} />

                  {/* <label id="Dashboard" style={{ fontSize: 28 }}>
                    &#129174;
                  </label> */}
                </div>
                <label
                  style={{
                    color: selectedDashboard === "Machine" ? "#E85C00" : "",
                    borderBottom:
                      selectedDashboard === "Machine" ? "3px solid #f27822" : "",
                  }}
                  onClick={() =>
                    onSelectTheOption(
                      "Machine",
                      "dashboards",
                      process.env.REACT_APP_MACHINE_DASHBOARD_URL
                    )
                  }
                  className="subMenustyle"
                >
                  Condition Monitoring
                </label>
              </div>
            ) : null}
            {isSelectedPaintsChemicals ? (
              <div className="menuSub z-10">
                {PaintsChemicalsDashboardArray.map((item, index) => (
                  <label
                    key={index}
                    style={{
                      color:
                        selectedDashboard === item._route_name ? "#E85C00" : "",
                      borderBottom:
                        selectedDashboard === item._route_name
                          ? "3px solid #f27822"
                          : "",
                    }}
                    className="subMenustyle"
                    onClick={() => {
                      onSelectTheOption(
                        item._route_name,
                        item._dirname,
                        item._dashboard_link
                      );
                      setIsSelectedPaintsChemicals(false);
                    }}
                    onMouseEnter={() => setIsSelectedPaintsChemicals(true)}
                    onMouseLeave={() => setIsSelectedPaintsChemicals(false)}
                  >
                    {item.name}
                  </label>
                ))}
              </div>
            ) : null}
            {isSupplierQualitySelected ? (
              <div className="menuSub  mt-[273px]">
                <label
                  className="subMenustyle"
                  style={{
                    color:
                      selectedDashboard === "TestMonitoring" ? "#E85C00" : "",
                    borderBottom:
                      selectedDashboard === "TestMonitoring"
                        ? "3px solid #f27822"
                        : "",
                  }}
                  onClick={() => {
                    onSelectTheOption(
                      "TestMonitoring",
                      "dashboards",
                      process.env.REACT_APP_CERTFICATE_DASHBOARD_URL
                    );
                    setIsSupplierQualitySelected(false)
                  }
                  }
                  onMouseEnter={() => setIsSupplierQualitySelected(true)}
                  onMouseLeave={() => setIsSupplierQualitySelected(false)}
                >
                  Test Certificate Monitoring
                </label>
              </div>
            ) : null}
          </div>

          <div>
            <div
              id="QualityIssue"
              style={{
                color: headerColor === "QualityIssue" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "QualityIssue"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className="menustyle"
              onClick={() => OnChangeSelectedOption("QualityIssue")}
            >
              Quality Issues▼
            </div>
            {isQualityIssuesSelected === true ? (
              <div className="header-menu">
                {QualityIssue.map((item, index) => (
                  <div
                    style={{
                      color: window.location.href.includes(item._route_name)
                        ? "#E85C00"
                        : "",
                      borderBottom: window.location.href.includes(
                        item._route_name
                      )
                        ? "3px solid #f27822"
                        : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        item.name,
                        item._route_name,
                        item._dashboard_link,
                        null
                      )
                    }
                    className="subMenustyle"
                    key={index}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            <label
              id="Events"
              style={{
                color: headerColor === "Events" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "Events"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              className="menustyle"
              onClick={() => OnChangeSelectedOption("Events")}
            >
              Events▼
            </label>
            {isSelectedEvent === true ? (
              <div className="header-menu">
                {Event.map((item, index) => (
                  <div
                    style={{
                      color: window.location.href.includes(item._route_name)
                        ? "#E85C00"
                        : "",
                      borderBottom: window.location.href.includes(
                        item._route_name
                      )
                        ? "3px solid #f27822"
                        : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        item.name,
                        item._route_name,
                        item._dashboard_link,
                        null
                      )
                    }
                    className="subMenustyle"
                    key={index}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="flex flex-row gap-3">
            {Application.map((item, index) => (
              <label className="LinkHidden">{item.name}</label>
            ))}
          </div>
          <div className="flex flex-row gap-3">
            <label
              style={{
                color: headerColor === "ReleaseNotes" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "ReleaseNotes"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              onClick={() => {
                dispatch(populate_user("ReleaseNotes"));
                navigate("/ReleaseNotes");
                localStorage.setItem("header", "ReleaseNotes");
              }}
              className="menustyle"
            >
              Release Notes
            </label>
          </div>
          <div className="flex flex-row gap-3">
            <label
              style={{
                color: headerColor === "ContactSupport" ? "#E85C00" : "",
                borderBottom:
                  headerColor === "ContactSupport"
                    ? "3px solid #f27822"
                    : "3px solid transparent",
              }}
              onClick={() => {
                dispatch(populate_user("ContactSupport"));
                navigate("/ContactSupport");
                localStorage.setItem("header", "ContactSupport");
              }}
              className="menustyle"
            >
              Contact Support
            </label>
          </div>
        </div>

        <div className="MenuIcon">
          <img className="Icons" src={MessageIcon} />
          <img
            onClick={() => dispatch(populate_user(1))}
            onPointerEnter={() => setIsNotificationSelected(true)}
            onPointerLeave={() => setIsNotificationSelected(false)}
            className="Icons"
            src={
              isNotificationSelected === true
                ? NotificationOrange
                : NotificationIcon
            }
          />
          <img
            onPointerEnter={() => setIsProfileSelected(true)}
            onPointerLeave={() => setIsProfileSelected(false)}
            className="Icons"
            onClick={() => onSelectTheOption("Profile", "Profile", null)}
            src={isProfileSelected === true ? UserOrange : ProfileIcon}
          />
          <img
            onPointerEnter={() => setIsLogoutSelected(true)}
            onPointerLeave={() => setIsLogoutSelected(false)}
            className="Icons"
            onClick={() => dispatch(populate_user(5))}
            src={isLogoutSelected === 5 ? LogoutOrange : LogoutIcon}
          />
        </div>
        <div className="2xl:hidden xl:hidden block">
          <img
            onClick={() => setIsSelectedMobileMenu(!isSelectedMobileMenu)}
            className="ProfileIcons"
            src={MenuIcon}
          />
        </div>
        <div className="mobileDotsIcon">
          <img
            onClick={() =>
              setIsSelectedMobileNotification(!isSelectedMobileNotitification)
            }
            id="Sidebar"
            className="ProfileIcons"
            src={DotsIcon}
          />
        </div>
      </div>
      {isSelectedMobileNotitification === true ? (
        <div className="mobileNotitficationIcon">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row items-center gap-2 cursor-pointer">
              <img className="Icons" src={MessageIcon} />
              <p>Messages</p>
            </div>
            <div
              onClick={() => dispatch(populate_user(1))}
              className="flex flex-row items-center gap-2  cursor-pointer"
            >
              <img
                onPointerEnter={() => setIsNotificationSelected(true)}
                onPointerLeave={() => setIsNotificationSelected(false)}
                className="Icons"
                src={
                  isNotificationSelected === true
                    ? NotificationOrange
                    : NotificationIcon
                }
              />
              <p>Notifications</p>
            </div>
            <div
              onClick={() => onSelectTheOption("Profile", "Profile", null)}
              className="flex flex-row items-center gap-2  cursor-pointer"
            >
              <img
                onPointerEnter={() => setIsProfileSelected(true)}
                onPointerLeave={() => setIsProfileSelected(false)}
                className="Icons"
                src={isProfileSelected === true ? UserOrange : ProfileIcon}
              />
              <p>Profile</p>
            </div>
            <div
              onClick={() => dispatch(populate_user(5))}
              className="flex flex-row items-center gap-2  cursor-pointer"
            >
              <img
                onPointerEnter={() => setIsLogoutSelected(true)}
                onPointerLeave={() => setIsLogoutSelected(false)}
                className="Icons"
                src={isLogoutSelected === 5 ? LogoutOrange : LogoutIcon}
              />
              <p>Logout</p>
            </div>
          </div>
        </div>
      ) : null}
      {isSelectedMobileMenu === true ? (
        <div className="moblieSidebar">
          <div className="flex flex-row items-center justify-end">
            <img
              onClick={() => setIsSelectedMobileMenu(false)}
              className="ProfileIcons"
              src={CloseIcon}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="moblieHidden">Introduction</div>
            <div>
              <div
                id="Masters"
                onClick={() => OnChangeSelectedOption("Masters")}
                className="moblieHeadMenu"
                style={{
                  color: headerColor === "Masters" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "Masters"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
              >
                <div id="Masters">Masters</div>
                <img
                  id="Masters"
                  className="ProfileIcons"
                  src={isMastersSelected === true ? DropupIcon : DropdownIcon}
                />
              </div>
              {isMastersSelected === true ? (
                <div className="flex flex-col ">
                  {Master.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                          null
                        )
                      }
                      className="moblieSidebarSub"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div>
              <div
                id="Processes"
                onClick={() => OnChangeSelectedOption("Processes")}
                className="moblieHeadMenu"
                style={{
                  color: headerColor === "Processes" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "Processes"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
              >
                <div id="Processes">Processes</div>
                <img
                  id="Processes"
                  className="ProfileIcons"
                  src={isProcessesSelected === true ? DropupIcon : DropdownIcon}
                />
              </div>
              {isProcessesSelected === true ? (
                <div className="flex flex-col ">
                  {Processes.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                          null
                        )
                      }
                      className="moblieSidebarSub"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div>
              <div
                id="Parameters"
                onClick={() => OnChangeSelectedOption("Parameters")}
                className="moblieHeadMenu"
                style={{
                  color: headerColor === "Parameters" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "Parameters"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
              >
                <div id="Parameters">Parameters</div>
                <img
                  id="Parameters"
                  className="ProfileIcons"
                  src={
                    isParametersSelected === true ? DropupIcon : DropdownIcon
                  }
                />
              </div>
              {isParametersSelected === true ? (
                <div className="flex flex-col ">
                  {Parameters.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                          null
                        )
                      }
                      className="moblieSidebarSub"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                  <div
                    id="Parameters"
                    onClick={() =>
                      setIsTolerancesSelected(!isTolerancesSelected)
                    }
                    className="flex flex-row items-center justify-between 2xl:text-xl xl:text-xl text-lg  py-[1.4%] text-[#092E3D] px-[4%] hover:text-[#FF7A22] border-b-2 cursor-pointer"
                    style={{
                      color: isTolerancesSelected === true ? "#E85C00" : "",
                    }}
                  >
                    <label id="Parameters">Tolerances</label>
                    <img
                      className="ProfileIcons"
                      src={
                        isTolerancesSelected === true
                          ? DropupIcon
                          : DropdownIcon
                      }
                    />
                  </div>
                </div>
              ) : null}
              {isTolerancesSelected === true ? (
                <div className="moblieSidebarSub">
                  <label
                    style={{
                      color: window.location.href.includes(
                        "InspectionParameters"
                      )
                        ? "#E85C00"
                        : "",
                      borderBottom: window.location.href.includes(
                        "InspectionParameters"
                      )
                        ? "3px solid #f27822"
                        : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        "InspectionParameters",
                        "InspectionParameters",
                        null
                      )
                    }
                  >
                    Inspection Parameters
                  </label>
                </div>
              ) : null}
            </div>
            <div>
              <div
                id="PeopleAccess"
                onClick={() => OnChangeSelectedOption("PeopleAccess")}
                className="moblieHeadMenu"
                style={{
                  color: headerColor === "PeopleAccess" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "PeopleAccess"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
              >
                <div id="PeopleAccess">People & Access</div>
                <img
                  id="PeopleAccess"
                  className="ProfileIcons"
                  src={
                    isPeoplAccessSelected === true ? DropupIcon : DropdownIcon
                  }
                />
              </div>
              {isPeoplAccessSelected === true ? (
                <div className="flex flex-col ">
                  {PeopleAccess.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                          null
                        )
                      }
                      className="moblieSidebarSub"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div>
              <div
                id="Dashboard"
                onClick={() => OnChangeSelectedOption("Dashboard")}
                style={{
                  color: headerColor == "Dashboard" ? "#E85C00" : "",
                  borderBottom:
                    headerColor == "Dashboard" ? "3px solid #f27822" : "3px solid transparent",
                }}
                className="moblieHeadMenu"
              >
                <div id="Dashboard">Dashboard</div>
                <img
                  id="Dashboard"
                  className="ProfileIcons"
                  src={isDashboardSelected ? DropupIcon : DropdownIcon}
                />
              </div>
              {isDashboardSelected ? (
                <div className="flex flex-col">
                  {DashboardArray.map((item, index) => (
                    <label
                      key={index}
                      style={{
                        color:
                          selectedDashboard == item._route_name
                            ? "#E85C00"
                            : "",
                        borderBottom:
                          selectedDashboard == item._route_name
                            ? "3px solid #f27822"
                            : "",
                      }}
                      className="moblieSidebarSub"
                      onClick={() =>
                        onSelectTheOption(
                          item._route_name,
                          item._dirname,
                          item._dashboard_link
                        )
                      }
                    >
                      {item.name}
                    </label>
                  ))}
                  <div
                    id="Dashboard"
                    onClick={() =>
                      setIsSelectedPaintsChemicals(!isSelectedPaintsChemicals)
                    }
                    className="moblieSidebarSub flex flex-row items-center justify-between"
                  >
                    <label
                      id="Dashboard"
                      style={{
                        color: isSelectedPaintsChemicals ? "#E85C00" : "",
                      }}
                    >
                      Paints & Chemicals
                    </label>
                    <div id="Dashboard">
                      <img
                        id="Dashboard"
                        className="ProfileIcons"
                        src={
                          isSelectedPaintsChemicals ? DropupIcon : DropdownIcon
                        }
                      />
                    </div>
                  </div>
                  {isSelectedPaintsChemicals ? (
                    <div id="Dashboard" className="flex flex-col">
                      {PaintsChemicalsDashboardArray.map((item, index) => (
                        <label
                          id="Dashboard"
                          key={index}
                          style={{
                            color:
                              selectedDashboard === item._route_name
                                ? "#E85C00"
                                : "",
                            borderBottom:
                              selectedDashboard === item._route_name
                                ? "3px solid #f27822"
                                : "",
                          }}
                          className="moblieSidebarSub"
                          onClick={() =>
                            onSelectTheOption(
                              item._route_name,
                              item._dirname,
                              item._dashboard_link
                            )
                          }
                        >
                          {item.name}
                        </label>
                      ))}
                    </div>
                  ) : null}
                  <label
                    style={{
                      color: selectedDashboard === "NCC" ? "#E85C00" : "",
                      borderBottom:
                        selectedDashboard === "NCC" ? "3px solid #f27822" : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        "NCC",
                        "dashboards",
                        process.env.REACT_APP_NCC_DASHBOARD_URL
                      )
                    }
                    className="moblieSidebarSub"
                  >
                    NCC
                  </label>
                  <div
                    onClick={() =>
                      setIsSupplierQualitySelected(!isSupplierQualitySelected)
                    }
                    id="Dashboard"
                    className="moblieSidebarSub flex flex-row items-center justify-between"
                    style={{
                      color:
                        isSupplierQualitySelected === true ? "#E85C00" : "",
                    }}
                  >
                    <label
                      id="Dashboard"
                      style={{
                        color:
                          isSupplierQualitySelected === true ? "#E85C00" : "",
                      }}
                    >
                      Supplier Quality
                    </label>
                    <div id="Dashboard">
                      <img
                        className="ProfileIcons"
                        src={
                          isSupplierQualitySelected === true
                            ? DropupIcon
                            : DropdownIcon
                        }
                      />
                    </div>
                  </div>

                  <div>
                    {isSupplierQualitySelected === true ? (
                      <div className="flex flex-col">
                        <label
                          className="moblieSidebarSub"
                          style={{
                            color:
                              selectedDashboard === "TestMonitoring"
                                ? "#E85C00"
                                : "",
                            borderBottom:
                              selectedDashboard === "TestMonitoring"
                                ? "3px solid #f27822"
                                : "",
                          }}
                          onClick={() =>
                            onSelectTheOption(
                              "TestMonitoring",
                              "dashboards",
                              process.env.REACT_APP_CERTFICATE_DASHBOARD_URL
                            )
                          }
                        >
                          Test Certificate Monitoring
                        </label>
                      </div>
                    ) : null}
                  </div>
                  <label
                    style={{
                      color: selectedDashboard === "Machine" ? "#E85C00" : "",
                      borderBottom:
                        selectedDashboard === "Machine" ? "3px solid #f27822" : "",
                    }}
                    onClick={() =>
                      onSelectTheOption(
                        "Machine",
                        "dashboards",
                        process.env.REACT_APP_MACHINE_DASHBOARD_URL
                      )
                    }
                    className="moblieSidebarSub"
                  >
                    Condition Monitoring
                  </label>
                </div>
              ) : null}
            </div>
            <div>
              <div
                id="QualityIssue"
                onClick={() => OnChangeSelectedOption("QualityIssue")}
                className="moblieHeadMenu"
                style={{
                  color: headerColor === "QualityIssue" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "QualityIssue"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
              >
                <div id="QualityIssue">Quality Issue</div>
                <img
                  id="QualityIssue"
                  className="ProfileIcons"
                  src={
                    isQualityIssuesSelected === true ? DropupIcon : DropdownIcon
                  }
                />
              </div>
              {isQualityIssuesSelected === true ? (
                <div className="flex flex-col ">
                  {QualityIssue.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                          null
                        )
                      }
                      className="moblieSidebarSub"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <div
                id="Events"
                onClick={() => OnChangeSelectedOption("Events")}
                className="moblieHeadMenu"
                style={{
                  color: headerColor === "Events" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "Events"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
              >
                <div id="Events">Events</div>
                <img
                  id="Events"
                  className="ProfileIcons"
                  src={isSelectedEvent === true ? DropupIcon : DropdownIcon}
                />
              </div>
              {isSelectedEvent === true ? (
                <div className="flex flex-col">
                  {Event.map((item, index) => (
                    <div
                      style={{
                        color: window.location.href.includes(item._route_name)
                          ? "#E85C00"
                          : "",
                        borderBottom: window.location.href.includes(
                          item._route_name
                        )
                          ? "3px solid #f27822"
                          : "",
                      }}
                      onClick={() =>
                        onSelectTheOption(
                          item.name,
                          item._route_name,
                          item._dashboard_link,
                          null
                        )
                      }
                      className="moblieSidebarSub"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col gap-3">
              {Application.map((item, index) => (
                <div key={index} className="moblieHidden">
                  {item.name}
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              <div
                style={{
                  color: headerColor === "ReleaseNotes" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "ReleaseNotes"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
                onClick={() => {
                  dispatch(populate_user("ReleaseNotes"));
                  navigate("/ReleaseNotes");
                  localStorage.setItem("header", "ReleaseNotes");
                }}
                className="moblieHeadMenu"
              >
                Release Notes
              </div>
            </div>
            <div className="flex flex-col">
              <div
                style={{
                  color: headerColor === "ContactSupport" ? "#E85C00" : "",
                  borderBottom:
                    headerColor === "ContactSupport"
                      ? "3px solid #f27822"
                      : "3px solid transparent",
                }}
                onClick={() => {
                  dispatch(populate_user("ContactSupport"));
                  navigate("/ContactSupport");
                  localStorage.setItem("header", "ContactSupport");
                }}
                className="moblieHeadMenu"
              >
                Contact Support
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
