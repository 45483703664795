import { createSlice } from "@reduxjs/toolkit";
export const SupplierSlice = createSlice({
  name: "supplier",
  initialState: {
    value: [],
  },
  reducers: {
    populate_supplier: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_supplier } = SupplierSlice.actions;
export default SupplierSlice.reducer;
