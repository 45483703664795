import { createSlice } from "@reduxjs/toolkit";
export const ContactSupportSlice = createSlice({
  name: "contactSupport",
  initialState: {
    value: [],
  },
  reducers: {
    populate_ContactSupport: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_ContactSupport } = ContactSupportSlice.actions;
export default ContactSupportSlice.reducer;
