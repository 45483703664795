import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Company } from "./Redux/CompanySlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { populate_Products } from "./Redux/ProductsSlice";
import { populate_Customer_Product } from "./Redux/CustomerProductSlice";
import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import AddPageButton from "../Button/AddPageButton";
import EditPageButton from "../Button/EditPageButton";
import ViewPageButton from "../Button/ViewPageButton";
import SuccessMessage from "../Constant/SuccessMessage";
import ManageSearch from "../Constant/ManageSearch";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function CustomerProduct() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON +
          "customerProductMap?size=1000",
          "Get"
        );
        window.addEventListener("click", (e) => {
          if (e.target.id != "Customer") {
            setOpenModelCustomer(false);
          }
          if (e.target.id != "Product") {
            setOpenModelProduct(false);
          }
        });
        FetchTheScreensToolTips();
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };
  let product = useSelector((state) => state.callProducts.value);
  const [productArray, setProductArray] = useState([]);
  const FetchTheProduct = (_bomMaster) => {
    if (product.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "productMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempProductMaster = [];
            let tempProductArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempProductMaster.push(response.data[i]);
                if (
                  tempProductArray.findIndex(
                    (e) => e === response.data[i].productName
                  ) == -1
                ) {
                  tempProductArray.push(response.data[i].productName);
                }
              }
            }
            dispatch(populate_Products(tempProductMaster));
            let tempProduct = [];
            for (let i = 0; i < tempProductMaster.length; i++) {
              if (
                _bomMaster.findIndex(
                  (e) => e.productDbId == tempProductMaster[i].productDbId
                ) != -1
              ) {
                tempProduct.push(tempProductMaster[i]);
              }
            }
            setSelectedProducts(tempProduct);
            setProductArray(tempProduct);
          }
        });
    } else {
      // let tempProductMaster = [...product];
      // let tempProduct = [];
      // for (let i = 0; i < tempProductMaster.length; i++) {
      //   tempProduct.push(tempProductMaster[i].productName);
      // }
      // setProductArray(tempProduct);
      // setSelectedProducts(tempProduct);
      let tempProduct = [];
      for (let i = 0; i < product.length; i++) {
        if (
          _bomMaster.findIndex(
            (e) => e.productDbId == product[i].productDbId
          ) != -1
        ) {
          tempProduct.push(product[i]);
        }
      }
      setSelectedProducts(tempProduct);
      setProductArray(tempProduct);
    }
  };
  const [customerArray, setCustomerArray] = useState([]);

  let companyMaster = useSelector((state) => state.callCompany.value);
  const FetchTheCompany = (_bomMaster) => {
    if (companyMaster.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "companyMaster")
        .then((response) => {
          if (response.status == 200) {
            let tempCompanyMaster = [];
            let tempCustomerArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempCompanyMaster.push(response.data[i]);
                if (
                  tempCustomerArray.findIndex(
                    (e) => e === response.data[i].name
                  ) == -1
                ) {
                  tempCustomerArray.push(response.data[i].name);
                }
              }
            }
            dispatch(populate_Company(tempCompanyMaster));
            let tempCustomer = [];
            for (let i = 0; i < tempCompanyMaster.length; i++) {
              if (
                _bomMaster.findIndex(
                  (e) => e.customerDbId == tempCompanyMaster[i].companyDbId
                ) != -1
              ) {
                tempCustomer.push(tempCompanyMaster[i]);
              }
            }
            setCustomerArray(tempCustomer);
            setSelectedCustomers(tempCustomer);
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    } else {
      // let tempCompanyMaster = [...companyMaster];
      // let tempCompany = [];
      // for (let i = 0; i < tempCompanyMaster.length; i++) {
      //   tempCompany.push(tempCompanyMaster[i].name);
      // }
      // setCustomerArray(tempCompany);
      // setSelectedCustomers(tempCompany);
      let tempCustomer = [];
      for (let i = 0; i < companyMaster.length; i++) {
        if (
          _bomMaster.findIndex(
            (e) => e.customerDbId == companyMaster[i].companyDbId
          ) != -1
        ) {
          tempCustomer.push(companyMaster[i]);
        }
      }
      setCustomerArray(tempCustomer);
      setSelectedCustomers(tempCustomer);
    }
  };

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "customerProductMap";

  let customerProductMap = useSelector(
    (state) => state.callCustomerProduct.value
  );

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [customerProductObjectErrors, setCustomerProductObjectErrors] =
    useState({
      customerNameError: "",
      productNameError: "",
      effDateFromError: "",
      effDateToError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "customerNameError");
    OnChangeErrorValues("", "productNameError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "customerNameError":
        setCustomerProductObjectErrors((customerProductObjectErrors) => ({
          ...customerProductObjectErrors,
          customerNameError: error,
        }));
        break;
      case "productNameError":
        setCustomerProductObjectErrors((customerProductObjectErrors) => ({
          ...customerProductObjectErrors,
          productNameError: error,
        }));
        break;

      case "effDateFromError":
        setCustomerProductObjectErrors((customerProductObjectErrors) => ({
          ...customerProductObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setCustomerProductObjectErrors((customerProductObjectErrors) => ({
          ...customerProductObjectErrors,
          effDateToError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = [];
    let filterProductArray = product.filter((e) =>
      e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);
    for (let i = 0; i < filterProductArray.length; i++) {
      let tempArray = customerProductMap.filter(
        (e1) => e1.productDbId == filterProductArray[i].productDbId
      );
      for (let j = 0; j < tempArray.length; j++) {
        temp.push(tempArray[j]);
      }
    }
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (customerProductMap.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(customerProductMap[i]);
        }
      } else {
        for (let i = 0; i < customerProductMap.length; i++) {
          tempArray.push(customerProductMap[i]);
        }
      }
      total_pages.current = Math.ceil(
        customerProductMap.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [customerProductMapArray, setCustomerProductMapArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [customerProductObject, setCustomerProductObject] = useState({
    productName: "",
    customerName: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });

  const [customerProductObjectDelete, setCustomerProductObjectDelete] =
    useState({
      productName: "",
      customerName: "",
      effDateFrom: new Date(),
      effDateTo: new Date("9999-12-31"),
      createdBy: "",
      updatedBy: "",
      createdTimestamp: new Date(),
      updatedTimestamp: new Date(),
    });
  const onResetValue = () => {
    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      productName: "",
    }));
    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      customerName: "",
    }));
    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      effDateFrom: new Date(),
    }));
    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productName":
        setCustomerProductObject((customerProductObject) => ({
          ...customerProductObject,
          productName: e.target.value,
        }));
        break;
      case "customerName":
        setCustomerProductObject((customerProductObject) => ({
          ...customerProductObject,
          customerName: e.target.value,
        }));
        break;
      case "effDateFrom":
        setCustomerProductObject((customerProductObject) => ({
          ...customerProductObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setCustomerProductObject((customerProductObject) => ({
          ...customerProductObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          customerProductMapArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            customerProductMapArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(customerProductMapArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            customerProductMapArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (customerProductMapArray.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(customerProductMapArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (searched_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
              (draft_array.length -
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(customerProductMapArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);

  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = customerProductMap.findIndex((e) => e.cpMapDbId === _code);
    console.log("Select index:" + idx);
    select_deselect_the_record(customerProductMap[idx]);
    set_item_to_delete(customerProductMap[idx].cpMapDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          if (response.status === 200) {
            ShowMEWSPopup(
              `${clientSideValidations.find((e) => e.validationId == 244)
                .shortMessage + " " + clientSideValidations.find((e) => e.validationId == 244)
                ?.validationCode
              }`
            );
            let temp = [...display_draft_array]
            temp.splice(temp.findIndex((e) => e.cpMapDbId == item_to_delete), 1);
            set_display_draft_array(temp);
            set_show_drafts(temp.length > 0 ? true : false)

            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "customerProductMap?size=1000",
              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup(error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup(
                `${clientSideValidations.find((e) => e.validationId == 245)
                  .shortMessage + " " + clientSideValidations.find((e) => e.validationId == 245)
                  ?.validationCode
                }`
              );
              let temp = [...display_draft_array]
              temp.splice(temp.findIndex((e) => e.defectDbId == item_to_delete), multiple_codes.length);
              set_display_draft_array(temp);
              set_show_drafts(temp.length > 0 ? true : false)

              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                "customerProductMap?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup(error.message, "error");
          });
      }
      set_multiple_codes([]);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      effDateFrom: customerProductObject.effDateFrom,
      effDateTo:
        status === true
          ? new Date("1900-01-01")
          : customerProductObject.effDateTo,
      productDbId: Number(customerProductObject.productName),
      customerDbId: Number(customerProductObject.customerName),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            ShowMEWSPopup(
              `${clientSideValidations.find((e) => e.validationId === 242)
                .shortMessage + " " + clientSideValidations.find((e) => e.validationId === 242)
                ?.validationCode
              }`
            );
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
              "customerProductMap?size=1000",

              "Post"
            );

            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup(error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditCustomerProduct = () => {
    let deleteData = {
      effDateFrom: GetServerDate(customerProductObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      productDbId: Number(customerProductObjectDelete.productName),
      customerDbId: Number(customerProductObjectDelete.customerName),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      effDateFrom: GetServerDate(customerProductObject.effDateFrom),
      effDateTo: new Date("9999-12-31"),
      productDbId: Number(customerProductObject.productName),
      customerDbId: Number(customerProductObject.customerName),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .patch(post_url + "/" + selectCpMapDbId, deleteData)
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(post_url, data)
              .then((response) => {
                if (response.status === 201) {
                  FetchTheData(
                    process.env.REACT_APP_BASE_API_PYTHON +
                    "customerProductMap?size=1000",
                    "Patch"
                  );
                  ShowMEWSPopup(
                    `${clientSideValidations.find((e) => e.validationId === 243)
                      .shortMessage + " " + clientSideValidations.find((e) => e.validationId === 243)
                      ?.validationCode
                    }`
                  );
                  set_mews_popup_color("#28A745");
                  set_open_edit_modal(false);
                  if (multiple_codes.length > 0) {
                    set_multiple_codes([]);
                  }
                  set_show_drafts(false)
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(data);
          ShowMEWSPopup(error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Customer Product Map") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "customerDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              customerProductObject.customerName == 0
            ) {
              temp++;
              setCustomerProductObjectErrors((customerProductObjectErrors) => ({
                ...customerProductObjectErrors,
                customerNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "productDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              customerProductObject.productName == 0
            ) {
              temp++;
              setCustomerProductObjectErrors((customerProductObjectErrors) => ({
                ...customerProductObjectErrors,
                productNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(customerProductObject.effDateFrom).getTime() >=
              new Date(customerProductObject.effDateTo).getTime() && new Date(customerProductObject.effDateTo).getTime() != new Date("1900-01-01").getTime()
            ) {
              temp++;
              setCustomerProductObjectErrors((customerProductObjectErrors) => ({
                ...customerProductObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          if (
            new Date(draft_array[i].effDateTo).getTime() ===
            new Date("1900-01-01").getTime()
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = customerProductMap.length;
      //Get The Total Pages
      let temp = 0
      for (let i = 0; i < customerProductMap.length; i++) {
        if (
          new Date(customerProductMap[i].effDateTo).getTime() ===
          new Date("9999-12-31").getTime()
        ) {
          temp += 1
        }
      }
      total_pages.current = Math.ceil(
        temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (customerProductMap.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < customerProductMap.length; i++) {
          if (
            new Date(customerProductMap[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
            temp_display_array.push(customerProductMap[i]);
          }
        }
      } else {
        if (
          customerProductMap.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
          process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          customerProductMap.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < customerProductMap.length;
            i++
          ) {
            if (
              new Date(customerProductMap[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(customerProductMap[i]);
            }
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            if (
              new Date(customerProductMap[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(customerProductMap[i]);
            }
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_customerProduct = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${clientSideValidations.find((e1) => e1.validationId == 241)
                  ?.shortMessage + " " + clientSideValidations.find((e) => e.validationId == 241)
                  ?.validationCode
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${clientSideValidations.find((e1) => e1.validationId == 389)
                  ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_customerProduct.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ==
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setCustomerProductMapArray(tempNonDraftArray);

            if (
              new Date(response.data[i].effDateTo).getTime() ==
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_Customer_Product(temp_customerProduct));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          set_draft_array(temp_draft_array);
          setPaginatedArray(temp_display_array);
          FetchTheCompany(tempNonDraftArray);

          FetchTheProduct(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup(error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectCpMapDbId, setSelectCpMapDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (cpMapDbId, type) => {
    setSelectCpMapDbId(cpMapDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(cpMapDbId);
  };
  const SupplyValuesToModal = (cpMapDbId) => {
    let dateFrom = customerProductMap.find(
      (e) => e.cpMapDbId == cpMapDbId
    ).effDateFrom;
    let dateTo = customerProductMap.find(
      (e) => e.cpMapDbId == cpMapDbId
    ).effDateTo;

    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      productName: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .productDbId,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      productName: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .productDbId,
    }));
    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      customerName: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .customerDbId,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      customerName: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .customerDbId,
    }));

    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      effDateFrom: dateFrom,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      effDateFrom: dateFrom,
    }));
    setCustomerProductObject((customerProductObject) => ({
      ...customerProductObject,
      effDateTo: dateTo,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      effDateTo: dateTo,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      createdBy: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .createdBy,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      updatedBy: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .updatedBy,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      createdTimestamp: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .createdTimestamp,
    }));
    setCustomerProductObjectDelete((customerProductObjectDelete) => ({
      ...customerProductObjectDelete,
      updatedTimestamp: customerProductMap.find((e) => e.cpMapDbId == cpMapDbId)
        .updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortPartMaster = [...customerProductMapArray];
    if (sortStyle === "Descending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.productName;
        const b_res = action === "Code" ? b.partCode : b.productName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedSupplier);
      setSortStyle("Ascending");
      setCustomerProductMapArray(updatedSupplier);
    } else if (sortStyle === "Ascending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.productName;
        const b_res = action === "Code" ? b.partCode : b.productName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedSupplier);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortPartMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    } else {
      for (let i = 0; i < SortPartMaster.length; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortPartMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setCustomerProductMapArray(SortPartMaster);
    setPaginatedArray(tempArray);
  };
  const [openModelCustomer, setOpenModelCustomer] = useState(false);
  const [openModelProduct, setOpenModelProduct] = useState(false);
  const OnClickFilter = (purpose) => {
    switch (purpose) {
      case "Product":
        // let tempProduct = [];
        // if (selectedProducts.length == 0) {
        //   for (let i = 0; i < product.length; i++) {
        //     tempProduct.push(product[i].productDbId);
        //   }
        // } else {
        //   for (let i = 0; i < selectedProducts.length; i++) {
        //     tempProduct.push(selectedProducts[i]);
        //   }
        // }
        // setSelectedProducts(tempProduct);
        if (customerProductMap.length == 0) {
          setOpenModelProduct(false);
        } else {
          setOpenModelProduct(!openModelProduct);
        }
        break;
      case "Customer":
        if (customerProductMap.length == 0) {
          setOpenModelCustomer(false);
        } else {
          setOpenModelCustomer(!openModelCustomer);
        }
        break;
    }
  };

  const OnOkAllFilter = (purpose) => {
    switch (purpose) {
      case "Product":
        let tempProduct = [...customerProductMap];
        let updatedProductArray = [];
        for (let i = 0; i < selectedProducts.length; i++) {
          for (let j = 0; j < tempProduct.length; j++) {
            if (
              tempProduct[j].productDbId === selectedProducts[i].productDbId
              // product.find((e) => e.productName == selectedProducts[i])
              //   .productDbId
            ) {
              if (
                new Date(tempProduct[j].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                updatedProductArray.push(tempProduct[j]);
              }
            }
          }
        }
        let tempProductArray = [];
        if (updatedProductArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempProductArray.push(updatedProductArray[i]);
          }
        } else {
          for (let i = 0; i < updatedProductArray.length; i++) {
            tempProductArray.push(updatedProductArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedProductArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setCustomerProductMapArray(updatedProductArray);
        setPaginatedArray(tempProductArray);
        setOpenModelProduct(false);
        break;
      case "Customer":
        let tempCustomer = [...customerProductMap];
        let updatedArray = [];
        for (let i = 0; i < selectedCustomers.length; i++) {
          for (let j = 0; j < tempCustomer.length; j++) {
            if (
              tempCustomer[j].customerDbId == selectedCustomers[i].companyDbId
            ) {
              if (
                new Date(tempCustomer[j].effDateTo).getTime() ==
                new Date("9999-12-31").getTime()
              ) {
                updatedArray.push(tempCustomer[j]);
              }
            }
          }
        }
        let tempArray = [];
        if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
          for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
            tempArray.push(updatedArray[i]);
          }
        } else {
          for (let i = 0; i < updatedArray.length; i++) {
            tempArray.push(updatedArray[i]);
          }
        }
        current_page.current = 1;
        total_pages.current = Math.ceil(
          updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
        );
        setCustomerProductMapArray(updatedArray);
        setPaginatedArray(tempArray);
        setOpenModelCustomer(false);
        break;
    }
  };
  const [searched_customer_array, setSearched_customr_array] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [search_product, setsearch_product] = useState("");
  const [search_product_array, setsearch_product_array] = useState([]);
  const OnChangeSearchFilter = (_option, e) => {
    switch (_option) {
      case "Product":
        let content = e.target.value;
        setsearch_product(content.toLowerCase());
        let temp = productArray.filter((e) =>
          e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
        );
        setsearch_product_array(temp);
        break;
      case "Customer":
        let contentCustomer = e.target.value;
        setSearchCustomer(contentCustomer.toLowerCase());
        let tempCustomer = customerArray.filter((e) =>
          e.name
            .toLowerCase()
            .trim()
            .match(contentCustomer.toLowerCase().trim())
        );
        setSearched_customr_array(tempCustomer);
        break;
    }
  };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const AddRemoveProduct = (id) => {
    let tempSelectedProduct = [...selectedProducts];
    let productIndex = tempSelectedProduct.findIndex(
      (e) => e.productDbId == id
    );
    if (productIndex == -1) {
      tempSelectedProduct.push(product.find((e) => e.productDbId == id));
    } else {
      tempSelectedProduct.splice(productIndex, 1);
    }
    setSelectedProducts(tempSelectedProduct);
  };
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const AddRemoveCustomers = (id, e) => {
    let tempSelectedCustomer = [...selectedCustomers];
    let customerIndex = tempSelectedCustomer.findIndex(
      (e) => e.companyDbId == id
    );
    if (customerIndex == -1) {
      tempSelectedCustomer.push(companyMaster.find((e) => e.companyDbId == id));
    } else {
      tempSelectedCustomer.splice(customerIndex, 1);
    }
    setSelectedCustomers(tempSelectedCustomer);
  };
  const screenCustomerProductCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);

  return (
    <div>
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />

          <div className="container-fluid">
            <SuccessMessage
              mews_popup_color={mews_popup_color}
              open_edit_modal={open_edit_modal}
              show_mews_popup={show_mews_popup}
              multiple_codes={multiple_codes}
              mews_message={mews_message}
            />
            {open_add_modal == false &&
              open_edit_modal == false &&
              open_view_modal == false ? (
              <>
                <ManageSearch
                  name="Customer Product Map"
                  OnClickShowDrafts={OnClickShowDrafts}
                  show_drafts={show_drafts}
                  toolTipName={
                    screensToolTips.find((e) => e.toolTipDbId == 427)
                      ?.toolTipDesc
                  }
                  search_content={search_content}
                  onChangeSearchContent={onChangeSearchContent}
                  placeholder="Customer Product Map"
                />
                <div className="tablerounds">
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "5%" }} />
                        <col span="1" style={{ width: "36%" }} />
                        <col span="1" style={{ width: "35%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <>
                                <img
                                  id="Product"
                                  className="Icons opacity-0"
                                  alt=""
                                />
                                Product
                                <img
                                  id="Product"
                                  className="Icons"
                                  onClick={() => OnClickFilter("Product")}
                                  src={FilterIcon}
                                  alt=""
                                />
                              </>
                              {openModelProduct === true ? (
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="Product"
                                          type="text"
                                          placeholder="Search"
                                          value={search_product}
                                          onChange={(e) =>
                                            OnChangeSearchFilter("Product", e)
                                          }
                                        />
                                        <div
                                          id="Product"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedProducts(productArray)
                                          }
                                        >
                                          <img
                                            id="Product"
                                            className="Icons"
                                            src={
                                              productArray.length ==
                                                selectedProducts.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Product">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {search_product.length === 0
                                          ? productArray.map((item, index) => (
                                            <div
                                              id="Product"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveProduct(
                                                  item.productDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="Product"
                                                className="Icons"
                                                src={
                                                  selectedProducts.findIndex(
                                                    (e) =>
                                                      e.productDbId ===
                                                      item.productDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="Product">
                                                {item.productName}
                                              </p>
                                            </div>
                                          ))
                                          : search_product.length > 0
                                            ? search_product_array.map(
                                              (item, index) => (
                                                <div
                                                  id="Product"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveProduct(
                                                      item.productDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Product"
                                                    className="Icons"
                                                    src={
                                                      selectedProducts.findIndex(
                                                        (e) =>
                                                          e.productDbId ===
                                                          item.productDbId
                                                      ) !== -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Product">
                                                    {item.productName}
                                                  </p>
                                                </div>
                                              )
                                            )
                                            : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="Product"
                                        className="popupfile_button"
                                        onClick={() => OnOkAllFilter("Product")}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Product"
                                        className="popupfile_button"
                                        onClick={() => setSelectedProducts([])}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <>
                                <img
                                  id="Customer"
                                  className="Icons opacity-0"
                                  alt=""
                                />
                                Customer
                                <img
                                  id="Customer"
                                  className="Icons"
                                  src={FilterIcon}
                                  onClick={() => OnClickFilter("Customer")}
                                  alt=""
                                />
                              </>
                              {openModelCustomer === true ? (
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="Customer"
                                          type="text"
                                          placeholder="Search"
                                          value={searchCustomer}
                                          onChange={(e) =>
                                            OnChangeSearchFilter("Customer", e)
                                          }
                                        />
                                        <div
                                          id="Customer"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedCustomers(customerArray)
                                          }
                                        >
                                          <img
                                            id="Customer"
                                            className="Icons"
                                            src={
                                              customerArray.length ==
                                                selectedCustomers.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Customer">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {searchCustomer.length == 0
                                          ? customerArray.map((item, index) => (
                                            <div
                                              id="Customer"
                                              key={index}
                                              className="Imageflex"
                                              onClick={() =>
                                                AddRemoveCustomers(
                                                  item.companyDbId
                                                )
                                              }
                                            >
                                              <img
                                                id="Customer"
                                                className="Icons"
                                                src={
                                                  selectedCustomers.findIndex(
                                                    (e) =>
                                                      e.companyDbId ==
                                                      item.companyDbId
                                                  ) != -1
                                                    ? CheckIcon
                                                    : UnCheckIcon
                                                }
                                                alt=""
                                              />
                                              <p id="Customer">{item.name}</p>
                                            </div>
                                          ))
                                          : searchCustomer.length > 0
                                            ? searched_customer_array.map(
                                              (item, index) => (
                                                <div
                                                  id="Customer"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveCustomers(
                                                      item.companyDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Customer"
                                                    className="Icons"
                                                    src={
                                                      selectedCustomers.findIndex(
                                                        (e) =>
                                                          e.companyDbId ==
                                                          item.companyDbId
                                                      ) != -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Customer">
                                                    {item.name}
                                                  </p>
                                                </div>
                                              )
                                            )
                                            : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="Customer"
                                        className="popupfile_button"
                                        onClick={() =>
                                          OnOkAllFilter("Customer")
                                        }
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Customer"
                                        className="popupfile_button"
                                        onClick={() => setSelectedCustomers([])}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  multiple_codes.findIndex(
                                    (e) => e === item.cpMapDbId
                                  ) !== -1
                                    ? "#DAE0E4"
                                    : "#FFFFFF",
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.cpMapDbId)
                                }
                              >
                                {index +
                                  1 +
                                  process.env.REACT_APP_ITEMS_PER_PAGE *
                                  (current_page.current - 1)}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.cpMapDbId)
                                }
                              >
                                {product.length > 0 &&
                                  product.findIndex(
                                    (e) => e.productDbId == item.productDbId
                                  ) != -1
                                  ? product.find(
                                    (e) => e.productDbId == item.productDbId
                                  ).productName
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.cpMapDbId)
                                }
                              >
                                {companyMaster.length > 0 &&
                                  companyMaster.findIndex(
                                    (e) => e.companyDbId == item.customerDbId
                                  ) != -1
                                  ? companyMaster.find(
                                    (e) =>
                                      e.companyDbId == item.customerDbId
                                  ).name
                                  : null}
                              </td>

                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.cpMapDbId)
                                }
                              >
                                {ReturnThePaddedValues(
                                  new Date(item.effDateFrom).getUTCDate()
                                ) +
                                  "-" +
                                  ReturnThePaddedValues(
                                    Number(
                                      new Date(item.effDateFrom).getUTCMonth()
                                    ) + 1
                                  ) +
                                  "-" +
                                  new Date(item.effDateFrom).getUTCFullYear()}
                              </td>
                              <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.cpMapDbId)
                                }
                              >
                                {new Date(item.effDateTo).getUTCDate() +
                                  "-" +
                                  (Number(
                                    new Date(item.effDateTo).getUTCMonth()
                                  ) +
                                    1) +
                                  "-" +
                                  new Date(item.effDateTo).getUTCFullYear()}
                              </td>
                              <td>
                                <IconComponent
                                  multiple_codes={multiple_codes}
                                  OpenModal={OpenModal}
                                  ShowDeleteConfirmation={
                                    ShowDeleteConfirmation
                                  }
                                  selectedFormDbId={item.cpMapDbId}
                                />
                              </td>
                            </tr>
                          ))
                          : show_drafts === true
                            ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.cpMapDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.cpMapDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                    (current_page.current - 1)}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.cpMapDbId)
                                  }
                                >
                                  {product.length > 0 &&
                                    product.findIndex(
                                      (e) => e.productDbId == item.productDbId
                                    ) != -1
                                    ? product.find(
                                      (e) => e.productDbId == item.productDbId
                                    ).productName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.cpMapDbId)
                                  }
                                >
                                  {companyMaster.length > 0 &&
                                    companyMaster.findIndex(
                                      (e) => e.companyDbId == item.customerDbId
                                    ) != -1
                                    ? companyMaster.find(
                                      (e) =>
                                        e.companyDbId == item.customerDbId
                                    ).name
                                    : null}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.cpMapDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.cpMapDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.cpMapDbId}
                                  />
                                </td>
                              </tr>
                            ))
                            : search_content.length > 0
                              ? paginatedArray.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.cpMapDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.cpMapDbId)
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.cpMapDbId)
                                    }
                                  >
                                    {product.length > 0 &&
                                      product.findIndex(
                                        (e) => e.productDbId == item.productDbId
                                      ) != -1
                                      ? product.find(
                                        (e) => e.productDbId == item.productDbId
                                      ).productName
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.cpMapDbId)
                                    }
                                  >
                                    {companyMaster.length > 0 &&
                                      companyMaster.findIndex(
                                        (e) => e.companyDbId == item.customerDbId
                                      ) != -1
                                      ? companyMaster.find(
                                        (e) =>
                                          e.companyDbId == item.customerDbId
                                      ).name
                                      : null}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.cpMapDbId)
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.effDateFrom).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(item.effDateFrom).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(item.effDateFrom).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.cpMapDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(item.cpMapDbId)
                                    }
                                  >
                                    {new Date(item.effDateTo).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.effDateTo).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(item.effDateTo).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <IconComponent
                                      multiple_codes={multiple_codes}
                                      OpenModal={OpenModal}
                                      ShowDeleteConfirmation={
                                        ShowDeleteConfirmation
                                      }
                                      selectedFormDbId={item.cpMapDbId}
                                    />
                                  </td>
                                </tr>
                              ))
                              : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <TableButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    screenCode={
                      screenCustomerProductCode.find((e) => e.screenId === 92)
                        ?.screenCode
                    }
                    onClickAddItem={onClickAddItem}
                    OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                    multiple_codes_delete={multiple_codes.length > 1}
                  />
                </div>
              </>
            ) : null}
            {/* //View box */}
            {open_view_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>View Customer Product Details</div>
                  <div>
                    {
                      screenCustomerProductCode.find((e) => e.screenId === 94)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Product</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          product.findIndex(
                            (e) =>
                              e.productDbId ===
                              customerProductObject.productName
                          ) != -1 ? product.find(
                            (e) =>
                              e.productDbId ===
                              customerProductObject.productName
                          ).productName : null
                        }
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Customer</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          companyMaster.findIndex(
                            (e) =>
                              e.companyDbId ===
                              customerProductObject.customerName
                          ) != -1 ? companyMaster.find(
                            (e) =>
                              e.companyDbId ===
                              customerProductObject.customerName
                          ).name : null
                        }
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Effective From</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            customerProductObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                customerProductObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              customerProductObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Effective To</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            customerProductObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                customerProductObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              customerProductObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>

                    {/* </div> */}
                  </div>
                </div>
                <ViewPageButton
                  close_modal={set_open_view_modal}
                  OpenModal={OpenModal}
                  selectedDbId={selectCpMapDbId}
                />
              </div>
            ) : null}
            {/* //Edit Popup column */}
            {open_edit_modal === true ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Edit Customer Product Details</div>
                  <div>
                    {
                      screenCustomerProductCode.find((e) => e.screenId === 95)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 154)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.productNameError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 5}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 154)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(5)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={customerProductObject.productName}
                        onChange={(e) => onChangeValue(e, "productName", null)}
                      >
                        <option value={null}>Select....</option>
                        {product.length > 0
                          ? product.map((item, index) =>
                            new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                              <option key={index} value={item.productDbId}>
                                {item.productName}
                              </option>
                            ) : null
                          )
                          : null}
                      </select>
                    </div>{" "}
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 156)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.customerNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 6}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 156)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(6)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={customerProductObject.customerName}
                        onChange={(e) => onChangeValue(e, "customerName", null)}
                      >
                        <option value={null}>Select....</option>
                        {companyMaster.length > 0
                          ? companyMaster.map((item, index) =>
                            new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                              <option key={index} value={item.companyDbId}>
                                {item.name}
                              </option>
                            ) : null
                          )
                          : null}
                      </select>
                    </div>{" "}
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 158)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.effDateFromError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 7}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 158)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(7)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            customerProductObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                customerProductObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              customerProductObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 160)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.effDateToError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 8}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 160)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(8)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            customerProductObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                customerProductObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              customerProductObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <EditPageButton
                  close_modal={set_open_edit_modal}
                  EditTheItem={OnEditCustomerProduct}
                />
              </div>
            ) : null}
            {/* //Delete Popup column */}
            {show_delete_confirmations ? (
              <DeleteConfirmationModal
                CancelTheDeletion={CancelTheDeletion}
                OnDeleteItem={OnDeleteItem}
                item_to_delete={item_to_delete}
              />
            ) : null}
            {/* //Add Popup column */}
            {open_add_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Add Customer Product Details</div>
                  <div>
                    {
                      screenCustomerProductCode.find((e) => e.screenId === 93)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 153)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.productNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 1}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 153)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(1)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={customerProductObject.productName}
                        onChange={(e) => onChangeValue(e, "productName", null)}
                      >
                        <option value={null}>Select....</option>
                        {product.length > 0
                          ? product.map((item, index) =>
                            new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                              <option key={index} value={item.productDbId}>
                                {item.productName}
                              </option>
                            ) : null
                          )
                          : null}
                      </select>
                    </div>{" "}
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 155)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.customerNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 2}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 155)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(2)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={customerProductObject.customerName}
                        onChange={(e) => onChangeValue(e, "customerName", null)}
                      >
                        <option value={null}>Select....</option>
                        {companyMaster.length > 0
                          ? companyMaster.map((item, index) =>
                            new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                              <option key={index} value={item.companyDbId}>
                                {item.name}
                              </option>
                            ) : null
                          )
                          : null}
                      </select>
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 157)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.effDateFromError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 3}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 157)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(3)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            customerProductObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                customerProductObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              customerProductObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 159)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {customerProductObjectErrors.effDateToError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 4}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 159)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(4)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            customerProductObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                customerProductObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              customerProductObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>

                <AddPageButton
                  close_modal={set_open_add_modal}
                  AddTheItem={AddTheItem}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default CustomerProduct;
