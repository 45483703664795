import React, { createContext, useEffect, useState } from "react";
import { store } from "../src/Components/Redux/store";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import RoutingComponent from "./Components/RoutingComponent";
import { onMessage } from "firebase/messaging";
import { messaging } from "./Components/FirebaseInit";
import axios from "axios";

export const ScreenCodeContext = createContext();

const App = () => {
  useEffect(() => {
    onMessage(messaging, (payload) => {
      alert(payload.notification.body);
      console.log("Message received. ", payload["notification"]);
      // ...
    });
    FetchTheScreen();
  }, []);
  React.useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  }, []);
  const [screensArray, setScreensArray] = useState([]);
  async function FetchTheScreen() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_API_PYTHON + "screens"
      );
      setScreensArray(response.data);
    } catch (error) {
      console.log(error);
    }
  }
 
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      }}
    >
      <ScreenCodeContext.Provider value={screensArray}>
        <Provider store={store}>
          <RoutingComponent />
        </Provider>
      </ScreenCodeContext.Provider>
    </Auth0Provider>
  );
};
export default App;
