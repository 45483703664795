import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { populate_user } from "../Components/Redux/UserSlice";

function LogoutConfirmation() {
  const { user, logout, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const LogoutUser = useSelector((state) => state.callUser.value);
  const LogoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    logout({
      logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI },
    });
  };

  return (
    <>
      {LogoutUser == 5 ? (
        <div className="w-screen h-screen absolute flex flex-col items-center justify-center inset-0">
        <div className="w-screen h-screen absolute bg-black opacity-[0.7] inset-0" />
        <div className="lg:max-w-3xl w-[90%] mx-auto lg:max-h-80 absolute max-h-72 rounded-xl h-full bg-white border-black">
        <div className="rounded-t-lg  lg:px-7 px-5 text-white h-[20%] xl:text-[1.25rem]  lg:text-[1rem] md:text-[1rem] sm:text-[0.875rem] xs:text-[0.75rem] bg-secondary-default flex flex-row items-center">
              <p>Confirmation</p>
            </div>
            <div className="flex flex-col items-center justify-center h-[60%] lg:px-7 px-5">
              Are you sure you want to logout ?
            </div>
            <div className="flex flex-row items-center justify-between gap-3 lg:px-7 px-5 rounded-b-xl h-[20%]">
              <button
                className="cancelButton"
                onClick={() => dispatch(populate_user(4))}
              >
                Cancel
              </button>
              <button className="saveButton" onClick={() => LogoutFunction()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default LogoutConfirmation;

// {
//     props.current_page,
//     props.total_pages,
//     onClickAddItem,
//     onPressNext,
//     onPressPrevious,
//     OnDeleteSelectionClicked,
//     array,
//   }
